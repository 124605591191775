import CoreService from './utils/core.service';

const route = 'FAQ';

const FAQSerivce = {
    getContents: () => CoreService.get(`${route}/contents`),
    voteAnswer: (item) => CoreService.post(`${route}/vote-answer`, item),
    createTicket: (item) => CoreService.post(`${route}/create-ticket`, item),
};

export default FAQSerivce;