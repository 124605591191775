import CoreService from './utils/core.service';

const route = 'Feedback';

const FeedbackSerivce = {
    get: () => CoreService.get(`${route}`),
    delete: (id) => CoreService.delete(`${route}/${id}`),
    send: (item) => CoreService.post(`${route}`, item),
};

export default FeedbackSerivce;