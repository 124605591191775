import React from 'react';
import i18next from 'i18next';
import { toJS } from 'mobx';
import Spinner from '../shared/spinner';
import NotificationsService from '../../services/notifications.service';
import { Card, CardBody, TabContent, TabPane, Nav, NavItem, NavLink, Button, Row, Col, InputGroup, InputGroupText } from 'reactstrap';
import Flatpickr from 'react-flatpickr';
import UtilsService from './../../services/utils/utils.service';
import MessageSerivce from './../../services/utils/message.service';
import { If } from 'react-if';
import { NotificationDeliveryStatus } from '../../app.values';
import Checkbox from './../shared/checkbox';
import { Link } from 'react-router-dom';


export default class NotificationsEdit extends React.Component {

    lastItemCpy = null;

    activeTab = 0;
    isEditing = false;
    isLoading = false;
    filter = {
        userSearchInput: '',
        sent: false,
        pending: false,
        accepted: false
    }
    item = {
        name: '',
        expirationDate: '',
        text: '',
        versions: []
    };

    constructor(props) {
        super(props);
    }

    componentWillMount() {
        _getItem.bind(this)(this.props.match.params.id);
    }

    setEdit = () => {
        this.isEditing = true;

        this.lastItemCpy = {
            name: this.item.name,
            expirationDate: this.item.expirationDate,
        };
    }

    cancelEdit = () => {
        this.isEditing = false;

        this.item.name = this.lastItemCpy.name;
        this.item.expirationDate = this.lastItemCpy.expirationDate;
    }

    setActiveTab = (tab) => {
        if (this.activeTab !== tab)
            this.activeTab = tab;
    }

    getStatusCount = (users, notificationDeliveryStatus) => {
        let filtered = users.filter(v => v.notificationDeliveryStatus === notificationDeliveryStatus);
        return filtered.length;
    }

    getUserList = (users) => {

        let filtered = this.filter.userSearchInput.length ?
            users.filter(v => UtilsService.containsSafe(v.email, this.filter.userSearchInput)) :
            users;

        return filtered.filter(v =>
            (!this.filter.sent && !this.filter.pending && !this.filter.accepted) ||
            (this.filter.sent && v.notificationDeliveryStatus === NotificationDeliveryStatus.Sent) ||
            (this.filter.pending && v.notificationDeliveryStatus === NotificationDeliveryStatus.Pending) ||
            (this.filter.accepted && v.notificationDeliveryStatus === NotificationDeliveryStatus.Accepted)
        ).sort((a, b) => a.notificationDeliveryStatus - b.notificationDeliveryStatus);
    }

    getNotificationDeliveryStatus = (item) => {
        switch (item.notificationDeliveryStatus) {
            case NotificationDeliveryStatus.Sent:
                return <span className="badge badge-primary">{i18next.t('SENT')}</span>;

            case NotificationDeliveryStatus.Accepted:
                return <span className="badge badge-success">{i18next.t('ACCEPTED')}</span>;

            case NotificationDeliveryStatus.Pending:
                return <span className="badge badge-warning">{i18next.t('PENDING')}</span>;

            default:
                return '-';
        }
    }

    onSubmit = (e) => {
        e.preventDefault();

        this.isLoading = true;

        NotificationsService
            .edit(this.item.id, toJS(this.item))
            .then(() => {
                MessageSerivce.success(i18next.t('SAVED'));
                this.isEditing = false;
            })
            .finally(() => this.isLoading = false);
    }

    render() {
        return (
            <Card className="notifications edit">
                <CardBody>
                    {!this.isEditing ?
                        <Row className="align-items-start">
                            <Col xs="8">
                                <div>
                                    <label>{i18next.t('NAME')}:</label>
                                    <strong className="ml-2 mr-3">{this.item.name}</strong>
                                    <Button color="link" size="sm" className="p-0" onClick={() => this.setEdit()}>
                                        <i className="fa fa-edit mr-1"></i>
                                        {i18next.t('EDIT')}
                                    </Button>
                                </div>
                                <div>
                                    <label>{i18next.t('EXPIRATION_DATE')}:</label>
                                    <strong className="ml-2 mr-3">{UtilsService.dateFormat(this.item.expirationDate, 'dd/M/yyyy')}</strong>
                                    <Button color="link" size="sm" className="p-0" onClick={() => this.setEdit()}>
                                        <i className="fa fa-edit mr-1"></i>
                                        {i18next.t('EDIT')}
                                    </Button>
                                </div>
                            </Col>
                            <Col className="text-right">
                                <Link to={`/notifications/create/${this.props.match.params.id}`}
                                    className="btn btn-sm btn-primary">
                                    <i className="fa fa-plus mr-1"></i>
                                    {i18next.t('NEW_VERSION')}
                                </Link>
                            </Col>
                        </Row> :
                        <form onSubmit={this.onSubmit}>
                            <Row className="align-items-end">
                                <Col lg="5" xl="7">
                                    <label>{i18next.t('NAME')}:</label>
                                    <input type="text"
                                        className="form-control"
                                        value={this.item.name}
                                        onChange={(e) => this.item.name = e.target.value}
                                        required />
                                </Col>
                                <Col lg="4" xl="3">
                                    <label>{i18next.t('EXPIRATION_DATE')}:</label>
                                    <Flatpickr
                                        className="form-control bg-white"
                                        options={{ dateFormat: "d/m/Y", locale: UtilsService.getFlatpickrLocale() }}
                                        value={new Date(this.item.expirationDate)}
                                        onChange={(date) => this.item.expirationDate = (date && date.length) ? date[0] : null}
                                        required />
                                </Col>
                                <Col lg="3" xl="2" className=" pl-0 pr-1">
                                    <Button type="button" color="link" size="sm" className="p-0 mr-2" onClick={() => this.cancelEdit()}>
                                        {i18next.t('CANCEL')}
                                    </Button>
                                    <Button type="submit" color="primary" size="sm">
                                        {i18next.t('SAVE')}
                                    </Button>
                                </Col>
                            </Row>
                        </form>
                    }
                    <div className="mt-4">
                        <Nav tabs>
                            {this.item.versions.map((item, index) =>
                                <NavItem key={index}>
                                    <NavLink
                                        className="cursor-pointer"
                                        active={this.activeTab === index}
                                        onClick={() => this.setActiveTab(index)}
                                    >
                                        {i18next.t('VERSION')} {' '} {item.number}
                                    </NavLink>
                                </NavItem>
                            )}
                        </Nav>
                        <TabContent activeTab={this.activeTab}>
                            {this.item.versions.map((item, index) =>
                                <TabPane key={index} tabId={index}>
                                    <div>
                                        <label className="d-block m-0">{i18next.t('TEXT')}: </label>
                                        <p className="text-muted">{item.text}</p>
                                    </div>
                                    <div className="mb-3">
                                        <label className="d-block m-0">{i18next.t('FILE')}: </label>
                                        {
                                            item.pathFile ?
                                                <a href={item.pathFile} download target="_blank">{item.pathFile}</a> :
                                                <span className="text-muted">{i18next.t('NO_FILE')}</span>
                                        }
                                    </div>
                                    <Row className="align-items-center mb-3" noGutters>
                                        <Col xs="9">
                                            <Checkbox
                                                id="sent"
                                                className="mr-3"
                                                label={`${i18next.t('SENT')} (${this.getStatusCount(item.users, NotificationDeliveryStatus.Sent)})`}
                                                checked={this.filter.sent}
                                                onChange={(checked) => { this.filter.sent = checked }} />

                                            <Checkbox
                                                id="pending"
                                                className="mr-3"
                                                label={`${i18next.t('PENDING')} (${this.getStatusCount(item.users, NotificationDeliveryStatus.Pending)})`}
                                                checked={this.filter.pending}
                                                onChange={(checked) => this.filter.pending = checked} />

                                            <Checkbox
                                                id="accepted"
                                                label={`${i18next.t('ACCEPTED')} (${this.getStatusCount(item.users, NotificationDeliveryStatus.Accepted)})`}
                                                checked={this.filter.accepted}
                                                onChange={(checked) => this.filter.accepted = checked} />
                                        </Col>
                                        <Col>
                                            <InputGroup>
                                                <input type="text"
                                                    className="form-control w-auto d-inline-block"
                                                    value={this.filter.userSearchInput}
                                                    onChange={(e) => this.filter.userSearchInput = e.target.value} />

                                                <div className="input-group-append">
                                                    <span className="input-group-text"> 
                                                        <i className="fa fa-search"></i>
                                                    </span>
                                                </div>
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                    <ul className="scrollable-list">
                                        {this.getUserList(item.users).map((item, index) =>
                                            <li key={index}>
                                                <span className="user-item-text">{item.email}</span>
                                                <span className="pull-right">
                                                    {this.getNotificationDeliveryStatus(item)}
                                                </span>
                                            </li>
                                        )}
                                        <If condition={!this.getUserList(item.users).length}>
                                            <li className="text-center">
                                                <span className="user-item-text text-muted">
                                                    {i18next.t('NO_RESULTS')}
                                                </span>
                                            </li>
                                        </If>
                                    </ul>
                                </TabPane>
                            )}
                        </TabContent>
                    </div>
                </CardBody>
                {this.isLoading && <Spinner />}
            </Card>
        );
    }

}

function _getItem(id) {
    this.isLoading = true;

    NotificationsService
        .get(id)
        .then((item) => {
            this.item = item;
            if (item.versions && item.versions.length > 1)
                this.activeTab = item.versions.length - 1;
        })
        .finally(() => this.isLoading = false)
}