import React from 'react';
import UtilsService from './../../services/utils/utils.service';
import { decimalChangeSuscriber } from '../decimal-customization/decimalCustomizationDropdown';

const DECIMALS_STORAGE_KEY = 'acsa-decimals';

export default class Currency extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            amount: this._parseAmount(this.props.amount)
        }
    }

    /**
     * render
     */
    render() {
        return (
            <React.Fragment>
                <span>{this.state.amount}</span>
                {
                    this.props.symbol !== false ?
                        <small style={{ 'width': '50px' }} className="d-inline-block text-bold text-left ml-2">
                            {this.props.symbol}
                        </small> :
                        null
                }
            </React.Fragment>
        );
    }

    /**
     * componentDidMount
     */
    componentDidMount() {
        decimalChangeSuscriber.subscribe(
            (res) => {
                // Update amounts with new decimals
                this.setState({
                    amount: this._parseAmount(this.props.amount),
                });

                console.log(res)
            }
        )
    }

    /**
     * componentWillUnmount
     */
    componentWillUnmount() {
        /* if(decimalChangeSuscriber) {
            decimalChangeSuscriber.unsubscribe();
        } */
    }

    /**
     * componentDidUpdate
     */
    componentDidUpdate(oldProps) {
        if(oldProps.amount !== this.props.amount) {
            this.setState({
                amount: this._parseAmount(this.props.amount)
            });
        }
    }

    _parseAmount(amount) {
        let decimals = localStorage.getItem(DECIMALS_STORAGE_KEY);
        if(!decimals || (decimals && decimals.trim() == '')) {
            decimals = 2;
        }

        return UtilsService.currency(amount, decimals);
    }
}