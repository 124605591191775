import React from 'react';
import i18next from 'i18next';
import Spinner from '../shared/spinner';
import MessageSerivce from '../../services/utils/message.service';
import ConfigurationsSerivce from './../../services/configurations.service';

const MonthsSelect = ({ value, onChange, ...rest }) => {
    let months = new Array(12).fill().map((e, i) => i + 1);

    return (
        <select className="form-control"
            value={value}
            onChange={onChange}
            required>
            {months.map(month =>
                <option key={month} value={month}>
                    {month} {i18next.t('MONTHS')}
                </option>
            )}
        </select>
    );
}

const HourSelect = ({ value, onChange, ...rest }) => {
    let hours = new Array(23).fill().map((e, i) => {
        let index = i + 1;

        return {
            id: index,
            value: index <= 9 ? `0${index}:00` : `${index}:00`
        };
    });

    return (
        <select className="form-control"
            value={value} onChange={onChange} required>
            {hours.map((item, index) =>
                <option key={index} value={item.id}>{item.value}</option>
            )}
        </select>
    );
}

export default class Configurations extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            item: {
                monthsLiveDurationForNotifications: 0,
                minimunMinutesIntervalBetweenNotifications: 0,
                avoidNotificationsDuringWeekend: true,
                minimunHourForSendNotifications: 0
            }
        };
    }

    componentWillMount() {
        this.getConfigurations();
    }

    getConfigurations() {
        this.setState({
            isLoading: true
        });

        ConfigurationsSerivce.get()
            .then((item) => item && (this.setState({item: item})))
            .finally(() => this.setState({isLoading: false}));
    }

    onSubmit = (e) => {
        e.preventDefault();

        this.setState({
            isLoading: true
        });
        
        ConfigurationsSerivce
            .save(toJS(this.state.item))
            .then(() => MessageSerivce.success(i18next.t('SAVED')))
            .finally(() => this.setState({isLoading: false}));
    }

    render() {
        return (
            <form className="configurations" onSubmit={this.onSubmit}>
                <div className="card">
                    <div className="card-body form-container">
                        <div className="form-group">
                            <label className="mr-2">
                                {i18next.t('CONFIGURATIONS_LABEL_FIELD_1')}:
                            </label>
                            <MonthsSelect
                                value={this.state.item.monthsLiveDurationForNotifications}
                                onChange={(e) => {
                                    this.setState({
                                        item: {
                                            ...this.state.item,
                                            monthsLiveDurationForNotifications: +e.target.value
                                        }
                                    });
                                }}
                                required />
                        </div>
                        <div className="form-group">
                            <label className="mr-2">
                                {i18next.t('CONFIGURATIONS_LABEL_FIELD_2')}:
                            </label>
                            <MonthsSelect
                                value={this.state.item.minimunMinutesIntervalBetweenNotifications}
                                onChange={(e) => {
                                    this.setState({
                                        item: {
                                            ...this.state.item,
                                            minimunMinutesIntervalBetweenNotifications: +e.target.value
                                        }
                                    });
                                }} />
                        </div>
                        <div className="form-group">
                            <label className="mr-2">
                                {i18next.t('CONFIGURATIONS_LABEL_FIELD_3')}:
                            </label>
                            <HourSelect
                                value={this.state.item.minimunHourForSendNotifications}
                                onChange={(e) => {
                                    this.setState({
                                        item: {
                                            ...this.state.item,
                                            minimunHourForSendNotifications: +e.target.value
                                        }
                                    });
                                }} />

                        </div>
                        <div className="form-group">
                            <label className="checkbox">
                                <input id="check"
                                    type="checkbox"
                                    checked={this.state.item.avoidNotificationsDuringWeekend}
                                    onChange={(e) => {
                                        this.setState({
                                            item: {
                                                ...this.state.item,
                                                avoidNotificationsDuringWeekend: +e.target.value
                                            }
                                        });
                                    }} />
                                <span className="checkmark"></span>
                            </label>
                            <label className="ml-1 cursor-pointer" htmlFor="check">
                                {i18next.t('CONFIGURATIONS_LABEL_FIELD_4')}
                            </label>
                        </div>
                        <div className="text-center mt-4">
                            <button type="submit" className="btn btn-sm btn-primary">
                                <i className="fa fa-save mr-2"></i>
                                {i18next.t('SAVE')}
                            </button>
                        </div>
                    </div>
                    {this.state.isLoading && <Spinner />}
                </div>
            </form>
        );
    }
}

