import React from 'react';
import DevicesSerivce from './../../services/devices.service';
import i18next from 'i18next';
import { Row, Col, Card, CardBody, Button } from 'reactstrap';
import UtilsService from './../../services/utils/utils.service';
import Spinner from '../shared/spinner';
import ConfirmaModal from '../shared/confirm.modal';

export default class Devices extends React.Component {

    isLoading;
    items = [];
    confirmModalConfig = {
        show: false
    }

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        _getDevices.bind(this)();
    }

    onDeleteClick = (id) => {

        this.confirmModalConfig.msg = `${i18next.t('ARE_YOU_SURE_TO_LOGOUT')}`;
        this.confirmModalConfig.show = true;
        this.confirmModalConfig.onSuccess = () => {
            DevicesSerivce
                .delete(id)
                .then(() => {
                    _getDevices.bind(this)();
                });
        };
    }

    render() {
        return (
            <div className="devices position-relative">
                <Row>
                    {this.items.map((item, index) =>
                        <Col key={index} lg="6" xl="4" className="mb-3">
                            <Card className="shadow">
                                <CardBody>
                                    <h6 className="text-bold text-primary">
                                        <i className="fa fa-mobile font-size-30 mr-2 align-middle"></i>
                                        <span>{item.model}</span>
                                    </h6>
                                    <div>
                                        <span className="mr-1">{i18next.t('NUMBER')}: </span>
                                        <span>{item.prefix}{' '}{item.number} </span>
                                    </div>
                                    <div>
                                        <span className="mr-1">{i18next.t('CODE')}: </span>
                                        <span>{item.identifier}</span>
                                    </div>
                                    <div className="mb-3">
                                        <span className="mr-1">{i18next.t('LAST_LOGIN')}: </span>
                                        <span>{UtilsService.dateFormat(item.lastLoginDate, 'dd/MM/yyyy hh:mm')}</span>
                                    </div>
                                    <Button color="danger" size="sm" onClick={() => this.onDeleteClick(item.id)}>
                                        {i18next.t('LOGOUT')}
                                    </Button>
                                </CardBody>
                            </Card>
                        </Col>
                    )}
                </Row>
                {this.confirmModalConfig.show && <ConfirmaModal config={this.confirmModalConfig} />}
                {this.isLoading && <Spinner />}
            </div>
        );
    }
}

function _getDevices() {
    this.isLoading = true;
    DevicesSerivce
        .get()
        .then((items) => this.items = items)
        .finally(() => this.isLoading = false)
}