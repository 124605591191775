import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import Routes from './app.routes';
import i18next from 'i18next';
import UtilsService from './services/utils/utils.service';
import { TranslateEN } from './translate/translate.en';
import { TranslateES } from './translate/translate.es';
import * as Sentry from "@sentry/react";
//import { BrowserTracing } from "@sentry/tracing";

//import SmartBanner from 'react-smartbanner';

//I18Next
i18next.init({
    lng: UtilsService.getDefaultLang(),
    debug: __IS_PROD__,
    nonExplicitWhitelist: true,
    resources: {
        es: TranslateES,
        en: TranslateEN
    },
}, () => {
    renderApp();
});

// Sentry
Sentry.init({
    dsn: "https://906a67ecbb674f6cb41b3f81477d95ae@sentry.ftsdn.com/13",
    integrations: [
        new Sentry.BrowserTracing(),
        new Sentry.Replay({
            maskAllText: true,
            blockAllMedia: true,
        }),
    ],
    tracesSampleRate: 0.25,
    replaysSessionSampleRate: 0.25,
    replaysOnErrorSampleRate: 1.0,
    environment: __IS_PROD__ ? 'production' : 'development',
});

/** Render app */
function renderApp() {
    const root = ReactDOM.createRoot(
        window.document.getElementById('app')
    );

    root.render(
        <BrowserRouter>
            {Routes}
        </BrowserRouter>
    );

    // SmartBanner \\
    /* ReactDOM.render(
        <SmartBanner
            daysHidden={0}
            daysReminder={0}
            title={'MiPortafolio'}
            button='Ver'
            price={{ios: 'Gratis', android: 'Gratis'}}
            storeText={{ios: 'En App Store', android: 'En Google Play'}}
            position='top'
        />,
        document.getElementById('smartbanner')
    ); */
}