import React from 'react';
import ReactDOM from 'react-dom';

export default class Spinner extends React.Component {

    parentEl = null;

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.parentEl = ReactDOM.findDOMNode(this).parentNode;
        this.parentEl && (this.parentEl.style.pointerEvents = 'none');
    }

    componentWillUnmount() {
        this.parentEl && (this.parentEl.style.pointerEvents = 'all');
    }

    render() {
        return (
            <div className="spinner">
                <div className={`spinner-backdrop ${this.props.absolute ? 'position-absolute' : ''}`}></div>
                <div className="spinner-wrapper">
                    <div className="spinner-icon" />
                </div>
            </div>
        )
    }
}