export const Side = {
    'Compra': {
        label: 'BUY',
        chevronDir: 'right'
    },
    'Venta': {
        label: 'SELL',
        chevronDir: 'left'
    },

    'Ingreso': {
        label: 'INCOME',
        chevronDir: 'right'
    },
    'Egreso': {
        label: 'EXIT',
        chevronDir: 'left'
    },

    'Tomador': {
        label: 'TAKER',
        chevronDir: 'right'
    },
    'Colocador': {
        label: 'PLACER',
        chevronDir: 'left'
    },

    'Vto. Tomador': {
        label: 'EXP_TAKER',
        chevronDir: 'right'
    },
    'Vto. Colocador': {
        label: 'EXP_PLACER',
        chevronDir: 'left'
    },

    'Liq. Anticipada Tomador': {
        label: 'ANTICIPATED_TAKER',
        chevronDir: 'right'
    },
    'Liq. Anticipada Colocador': {
        label: 'ANTICIPATED_PLACER',
        chevronDir: 'left'
    },

    'Vto. Liq. Ant. Tomador': {
        label: 'EXP_ANTICIPATED_TAKER',
        chevronDir: 'right'
    },
    'Vto. Liq. Ant. Colocador': {
        label: 'EXP_ANTICIPATED_PLACER',
        chevronDir: 'left'
    },

    'Suscripción': {
        label: 'SUBSCRIPTION',
        chevronDir: 'right'
    },
    'Rescate': {
        label: 'RESCUE',
        chevronDir: 'left'
    },

    'Contraparte Suscripción': {
        label: 'COUNTERPART_SUBSCRIPTION',
        chevronDir: 'right'
    },
    'Contraparte Rescate': {
        label: 'COUNTERPART_RESCUE',
        chevronDir: 'left'
    },

    'Tenedor': {
        label: 'FORK',
        chevronDir: 'right'
    },
    'Emisor': {
        label: 'TRANSMITTER',
        chevronDir: 'left'
    },

    'Contraparte Tenedor': {
        label: "COUNTERPART_TRANSMITTER",
        chevronDir: 'right'
    },
    'Contraparte Emissor': {
        label: 'COUNTERPART_FORK',
        chevronDir: 'left'
    },
   
};

export const NotificationDeliveryStatus = {
    Pending: 0,
    Sent: 1,
    Accepted: 2
}