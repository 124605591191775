import React from 'react';
import i18next from 'i18next';

export default class InputFile extends React.Component {

    state = {
        file: undefined
    }

    constructor(props) {
        super(props);

        this.fileInput = React.createRef();
    }

    onFileSelect = (e) => {
        let fileList = e.target.files;
        if (fileList.length > 0) {
            let reader = new FileReader();

            reader.onload = (event) => { }

            let file = fileList[0];
            if (file) {
                reader.readAsDataURL(file);

                this.setState({ file });
                this.props.onChange(file);
            }
        }
    }

    onFileClean = (e) => {
        e.preventDefault();

        this.setState({ file: null });
        this.fileInput.current && (this.fileInput.current.value = '');

        this.props.onChange(null);
    }

    render() {
        return (
            <div className="file-input">
                <div className="file-button">
                    <input id={this.props.id || 'fileInput'} type="file"
                        onChange={this.onFileSelect}
                        ref={this.fileInput}
                        accept={this.props.accept || null}
                        className="d-none" />
                    {
                        this.state.file == null ?
                            <span className="d-flex w-100">
                                <span className="my-auto pl-3" style={{ color: '#aaa' }}>{i18next.t('UPLOAD_FILE')}...</span>
                                <label htmlFor={this.props.id || 'fileInput'} className="btn btn-primary">
                                    <i className="fa fa-upload mr-2"></i>
                                    {i18next.t('UPLOAD')}
                                </label>
                            </span> :
                            <span className="d-flex w-100">
                                <span className="my-auto pl-3">
                                    <i className={`fa fa-file-o mr-2`}></i>
                                    {this.state.file.name}
                                </span>
                                <label className="file-input-clear btn btn-danger" onClick={this.onFileClean}>
                                    <i className="fa fa-times mr-2"></i>
                                    {i18next.t('CLEAR')}
                                </label>
                            </span>
                    }
                </div>
            </div>
        )
    }
}