import React from 'react';
import i18next from 'i18next';
import { NavLink } from 'react-router-dom';
import IdentitySerivce from './../../services/indentity.service';

const menuTree = [
    {
        sectionTitleKey: 'ADMINISTRATION',
        admin: true,
        submenu: [
            {
                titleKey: 'NOTIFICATIONS',
                link: '/notifications',
                icon: 'fa-bell'
            },
            {
                titleKey: 'SECURITY',
                link: '/security',
                icon: 'fa-lock'
            },
            {
                titleKey: 'FEEDBACK',
                link: '/feedback',
                icon: 'fa-comments'
            },
        ]
    },
    {
        admin: false,
        submenu: [
            {
                titleKey: 'PORTFOLIO',
                link: '/portfolio',
                icon: 'fa-briefcase'
            },
            {
                titleKey: 'HISTORICAL',
                link: '/historical',
                icon: 'fa-history'
            },
            /* {
                titleKey: 'RESULT_CANCELLATIONS',
                link: '/cancellations',
                icon: 'fa-history'
            }, */
            {
                titleKey: 'MY_ACCOUNTS',
                link: '/accounts',
                icon: 'fa-address-card-o'
            },
            {
                titleKey: 'HELP',
                link: '/help',
                icon: 'fa-question'
            },
            /* {
                titleKey: 'MY_DEVICES',
                link: '/devices',
                icon: 'fa-mobile'
            } */
        ]
    }
]

export default class Sidebar extends React.Component {

    constructor(props) {
        super(props);
    }

    isAllowed = (item) => {
        let isAdmin = IdentitySerivce.isAdmin();

        return (item.admin && isAdmin) ||
            (!item.admin && !isAdmin)
    }

    render() {
        return (
            <div id="sidebar">
                {
                    menuTree.map((item, index) =>
                        this.isAllowed(item) ?
                            <section key={index} className="sidebar-section">
                                {
                                    item.sectionTitleKey ?
                                        <h6 className="sidebar-title">{i18next.t(item.sectionTitleKey)}</h6> :
                                        null
                                }
                                {
                                    item.submenu.map((subItem, subIndex) =>
                                        <NavLink 
                                            key={subIndex}
                                            to={subItem.link} 
                                            style={isActive => ({
                                                color: isActive ? "active" : ""
                                            })}
                                        >
                                            <i className={`fa ${subItem.icon} fa-fw sidebar-link-icon`}></i>
                                            <span className="sidebar-link-text">{i18next.t(subItem.titleKey)}</span>
                                        </NavLink>
                                    )
                                }
                            </section> :
                            null
                    )
                }
            </div>
        );
    }
}