import CoreService from './utils/core.service';

const route = 'backOffice';

const BackOfficeSerivce = {
    getStock: (filter) => CoreService.get(`${route}/stock`, filter),
    getHoldingInstrument: (filter) => CoreService.get(`${route}/holdings/instrument`, filter),
    getMovements: (filter) => CoreService.get(`${route}/movements`, filter),
    getHeaders: (filter) => CoreService.get(`${route}/history/headers`, filter),
    getHistory: (filter) => CoreService.get(`${route}/history`, filter),
    getClearingMembers: () => CoreService.get(`${route}/clearingMembers?version=related-accounts`),
    getClearingMenbersOld: () => CoreService.get(`${route}/clearingMembers`),
    getDateperiods: () => CoreService.get(`${route}/dataprovider/date-periods`),
};

export default BackOfficeSerivce;