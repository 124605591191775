import React from 'react';
import i18next from 'i18next';
import { Link } from 'react-router-dom';
import Spinner from '../../shared/spinner';
import IdentitySerivce from '../../../services/indentity.service';
import AuthChangeLangBtn from './../authChangeLangBtn';
import EmailSent from '../resetPassword/emailSent';

export default class ResetPassword extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            emailSent: false,
            email: '',
        };
    }

    onSubmit = (e) => {
        e.preventDefault();

        this.setState({
            isLoading: true
        });

        IdentitySerivce
            .resetPassword(this.state.email)
            .then(() => this.setState({ emailSent: true }))
            .finally(() => this.setState({ isLoading: false }));
    }

    render() {
        return (
            <div className="auth">
                <div className="auth-container">
                    <div className="text-center mb-2">
                        <img height="48" src="/assets/img/logo.png" />
                    </div>
                    <div className="card">
                        <div className="card-body">
                            {!this.state.emailSent ?
                                <form onSubmit={this.onSubmit} autoComplete="off">
                                    <h3 className="text-muted text-center">{i18next.t('RESET_PASSWORD')}</h3>
                                    <p className="text-muted text-center mb-4 mt-1">{i18next.t('RESET_PASSWORD_INFO_TEXT')}</p>
                                    <div className="form-group">
                                        <input type="email" className="form-control"
                                            placeholder={i18next.t('EMAIL')}
                                            value={this.state.email}
                                            onChange={(e) => this.setState({email: e.target.value})}
                                            autoComplete="new-email-resend"
                                            required />
                                    </div>
                                    <button type="submit" className="btn btn-info btn-lg w-100">
                                        {i18next.t('SEND_LINK')}
                                    </button>
                                    <div className="mt-3 text-center">
                                        <Link to="/login" className="w-100">
                                            {i18next.t('BACK_TO_LOGIN')}
                                        </Link>
                                    </div>
                                </form>
                                : 
                                <EmailSent onResendClick={() => this.setState({emailSent: false})} />  
                            }

                            <div className="text-center mt-3">
                                <AuthChangeLangBtn onChange={() => this.forceUpdate()} />
                            </div>
                        </div>
                    </div>
                    {this.state.isLoading && <Spinner />}
                </div>
            </div>
        );
    }
};