import React from 'react';
import i18next from 'i18next';
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Row, Col, Button, Card, CardBody } from 'reactstrap';
import Spinner from '../shared/spinner';
import UtilsService from './../../services/utils/utils.service';
import { Subject } from 'rxjs';

const DECIMALS_STORAGE_KEY = 'acsa-decimals';
function getDecimals() {
    let decimals = localStorage.getItem(DECIMALS_STORAGE_KEY);
    if(!decimals || (decimals && decimals.trim() == '')) {
        decimals = 2;
    }

    return decimals;
}

const decimalChangeSubject = new Subject();
export const decimalChangeSuscriber = decimalChangeSubject.asObservable();

export class DecimalCustomizationDropdown extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
            item: {
                decimals: getDecimals()
            },
            isLoading: false
        };
    }

    toggleOpen = () => {
        let isOpen = !this.state.isOpen;

        this.setState({
            isOpen: isOpen,
            item: {
                decimals: getDecimals()
            }
        });
    }

    onSubmit = (e) => {
        e.preventDefault();

        // New decimals value
        let decimals = this.state.item.decimals;
        if(!UtilsService.isNumeric(decimals)) {
            return;
        }

        // Show loading
        this.setState({
            isLoading: true
        });

        // Save records
        localStorage.setItem(DECIMALS_STORAGE_KEY, decimals);

        setTimeout(() => {
            // Hide loading
            this.setState({
                isLoading: false
            })

            // Change on site
            decimalChangeSubject.next(decimals);
        }, 200);
    }

    render() {
        return (
            <React.Fragment>
                <ButtonDropdown nav inNavbar isOpen={this.state.isOpen} toggle={this.toggleOpen}>
                    <DropdownToggle nav title={i18next.t('DECIMAL_CUSTOMIZATION')}>
                        <i className="fa fa-sliders font-size-22 align-middle"></i>
                    </DropdownToggle> 

                    <DropdownMenu end style={{ width: '400px' }}>
                        <DropdownItem tag="div" toggle={false} className="dropdown-item-white">
                            <div className="border-bottom"
                                style={{ margin: '0 -1.5rem 1rem', padding: '0 1.5rem 0.5rem', whiteSpace: 'normal' }}>
                                <strong>{i18next.t('DECIMAL_CUSTOMIZATION_SUBTITLE')}</strong>
                            </div>
                            <form onSubmit={this.onSubmit}>
                                <span className="d-block font-size-14 mb-2" style={{ whiteSpace: 'normal' }}>
                                    {i18next.t('DECIMAL_CUSTOMIZATION_FIELD_DECIMALS')}:
                                </span>
                                <div className="mb-3">
                                    <input 
                                        placeholder={`${i18next.t('ENTER_DECIMALs_HERE')}...`}
                                        className="form-control"
                                        type="number"
                                        value={this.state.item.decimals}
                                        onChange={(e) => this.setState({item: { decimals: e.target.value}})}
                                        required
                                        min="0"
                                        max="8" />
                                </div>

                                <div className="mt-3 text-center">
                                    <Button type="submit" color="primary" className="w-100">
                                        {i18next.t('SAVE')}
                                    </Button>
                                </div>
                            </form>
                        </DropdownItem>
                        {this.state.isLoading && <Spinner />}
                    </DropdownMenu>
                </ButtonDropdown >
            </React.Fragment>

        )
    }
}