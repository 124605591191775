export const TranslateES = {
    translation: {
        //GENERAL FOR ALL SCREENS
        "ERROR_MSG": "Ha ocurrido un error inesperado en el servidor. Por favor, intente realizar la acción nuevamente.",
        "ARE_YOU_SURE_TO_DELETE": "¿Está seguro que desea eliminar?",
        "DELETED": "Eliminado exitosamente",
        "SAVED": "Guardado exitosamente",
        "CREATED": "Creado exitosamente",
        "REQUIRED_FIELDS": "Campos Requeridos",
        "COMPLETE_ALL_FIELDS": "Por favor, complete todos los campos",
        "OK": "Ok",
        "YES": "Si",
        "NO": "No",
        "YOU": "Tu",
        "DELETE": "Eliminar",
        "ADD": "Agregar",
        "NEW": "Nuevo",
        "UPLOAD": "Subir",
        "CANCEL": "Cancelar",
        "CLOSE": "Cerrar",
        "SAVE": "Guardar",
        "EDIT": "Editar",
        "VIEW": "Ver",
        "CHANGE": "Cambiar",
        "SEARCH": "Buscar",
        "ALL": "Todos",
        "CONFIRMATION": "Confirmación",
        "DOWNLOAD": "Descargar",
        "DOWNLOAD_XLSX": "Solicitar reporte",
        "DOWNLOAD_XLSX_ERROR": "Ocurrió un error al generar la exportación. Por favor, vuelve a intentarlo.",
        "NO_RESULTS": "No se han encontrado resultados",
        "NO_DATA": "No se han encontrado datos",
        "FILE": "Archivo",
        "LINK": "Link",
        "TYPE": "Tipo",
        "TITLE": "Título",
        "NAME": "Nombre",
        "PAGE_NOT_FOUND": "Página no encontrada",
        "CLEAR_FILTERS": "Limpiar Filtros",
        "CLEAR": "Borrar",
        "NO_ACCOUNTS_FOUND": "No se han encontrado cuentas",
        "SEUO": "S.E.U.O",
        "SEUO_TITLE": "Salvo Error u Omisión",
        "WARNING": "Advertencia",
        "AGREE": "Aceptar",
        "DECLINE": "Rechazar",
        "CONTINUE": "Continuar",

        // EXPORT MESSAGES
        "EXPORT_QUEUE": "Solicitud de reporte generada",
        "EXPORT_QUEUE_MESSAGE": "Hemos generado tu solicitud de envío de reporte. Será enviado a tu dirección de correo en las próximas horas. Por favor revisá tu bandeja de entrada y de correo no deseado (SPAM)",

        //ERROR MESSAGES
        "INVALID_CREDENTIALS": "Credenciales Invalidas",
        "PASSWORD_ALREADY_EXISTS": "La contraseña debe ser distinta de la anterior.",

        //HEADER
        "LOGOUT": "Cerrar Sesión",
        "LOGIN_AS": "Ingresar como",
        "ADD_ACCOUNT": "Añadir Cuenta",

        //MENU
        "MY_DEVICES": "Mis Dispositivos",
        "ADMINISTRATION": "Administración",
        "MY_PORTFOLIO": "Mi Portfolio",
        "MY_ACCOUNTS": "Mis Cuentas",
        "HELP": "Ayuda",

        //LOGIN
        "LOGIN": "Iniciar Sesión",
        "USER_OR_PASSWORD_INVALID": "Credenciales Inválidas. Usuario Bloqueado o Password vencida. Por razones de seguridad deberá resetear la contraseña.",
        "RESET_PASSWORD": "Olvidé mi contraseña / Quiero resetear mi contraseña",
        "EMAIL": "Email",
        "PASSWORD": "Contraseña",
        "ARE_YOU_ADMIN": "¿Eres administrador? Click aquí",
        "REGISTER_FIRST_ACCESS": "¿Primer acceso? Click aquí",
        "REMEMBER_USER": "Mantener sesión iniciada",

        //RESET
        "RESET_PASSWORD_INFO_TEXT": "Si olvidaste tu contraseña, escribe tu email y te enviaremos un link con la informacion para cambiarla",
        "BACK_TO_LOGIN": "Volver al inicio de sesión",
        "SEND_LINK": "Enviar Link",
        "WE_SENT_AN_EMAIL": "Te hemos enviado un correo",
        "CHECK_YOUR_EMAIL_INBOX": "Revisa tu casilla de correo. Recuerda que el envio puede tardar unos minutos",
        "HAVENT_YOU_RECEIVED_AN_EMAIL": "¿No te ha llegado el email?",

        //WELCOME
        "WELCOME_TITLE": "Bienvenido a Argentina Clearing",
        "WELCOME_INFO_TEXT": "Por favor, escribe tu email y tu contraseña temporal para dar continudad al proceso de registro",
        "WELCOME_SUBMIT": "Continuar",

        //CHANGE PASSWORD
        "CHANGE_PASSWORD": "Cambiar Contraseña",
        "OLD_PASSWORD": "Contraseña Anterior",
        "NEW_PASSWORD": "Nueva Contraseña",
        "NEW_PASSWORD_REPEATED": "Repita la Nueva Contraseña",
        "CHANGE": "Cambiar",
        "PASSWORD_CHANGED": "La contraseña ha sido cambiada",

        "PASSWORD_NOT_COMPLETE_REQUERIMENTS": "La contraseña no cumple con alguno de los requisitos.",
        "REPEATED_PASSWORD_NOT_MATCH": "La contraseña repetida no coincide.",
        "EXPIRED_TOKEN": "Este correo ya fue utilizado antes. Por favor vuelva a solicitarlo",

        "HOW_TO_CHOOSE_PASSWORD_LINK": "¿Cómo elegir la contraseña?",
        "HOW_TO_CHOOSE_PASSWORD_TITLE": "¿Cómo elegir la contraseña?",
        "HOW_TO_CHOOSE_PASSWORD_DESCRIPTION_1": "Las contraseñas deben tener:",
        "HOW_TO_CHOOSE_PASSWORD_DESCRIPTION_2": "* Mínimo 6 caracteres de longitud.",
        "HOW_TO_CHOOSE_PASSWORD_DESCRIPTION_3": "* Una letra mayúscula [A-Z].",
        "HOW_TO_CHOOSE_PASSWORD_DESCRIPTION_4": "* Un caracter numérico [0-9].",
        "HOW_TO_CHOOSE_PASSWORD_DESCRIPTION_5": "* Debe ser distinta a la última utilizada.",
        "HOW_TO_CHOOSE_PASSWORD_DESCRIPTION_6": "* No debe incluir el caracter especial &.",

        //MIS DISPOSITIVOS
        "NUMBER": "Número",
        "CODE": "Código",
        "LAST_LOGIN": "Último login",
        "DELETE_DEVICE": "Eliminar Dispositivo",
        "ARE_YOU_SURE_TO_LOGOUT": "¿Estás seguro que quieres cerrar la sesión en este dispositivo?",

        //NOTIFICATIONS
        "NOTIFICATIONS": "Notificaciones",
        "NOTIFICATIONS_CREATE_NEW_VERSION": "Notificaciones: Crear nueva versión",
        "NOTIFICATIONS_CREATE": "Notificaciones: Crear",
        "NOTIFICATIONS_EDIT": "Notificaciones: Editar",
        "CREATE_NOTIFICATION": "Crear Notificación",
        "VIEW_NOTIFICATION": "Ver Notificación",
        "SHOW_ONLY_LAST_VERSIONS": "Mostrar solo últimas versiones",
        "VERSION": "Versión",
        "NUMBER_SENT": "Cantidad de envíos",
        "NUMBER_ACCEPTED_PEOPLE": "Cantidad de personas aceptadas",
        "TEXT": "Texto",
        "UPLOAD_FILE": "Subir Archivo",
        "SEARCH_AND_ADD_USERS": "Buscar y agregar usuarios",
        "SEARCH_PLACEHOLDER": "Escriba el email del usuario (2 letras)",
        "SELECTED": "Seleccionados",
        "NONE_USER_SELECTED": "Ningún usuario seleccionado",
        "SAVE_AND_SEND": "Guardar y Enviar",
        "NEW_VERSION": "Nueva versión",
        "EXPIRATION_DATE": "Fecha de expiración",
        "NO_FILE": "No hay archivo adjunto",
        "SENT": "Enviado",
        "PENDING": "Pendiente",
        "ACCEPTED": "Recibido",
        "SEND_ONLY_PEOPLE_NOT_ACCEPTED": "Enviar solo a las personas que no acusaron el recibo",
        "AUTO_ACCEPT_OLD_VERSIONS": "Auto-aceptar version anterior y enviar a todos",
        "ARE_YOU_SURE_TO_DELETE_NOTIFICATIONS": "¿Estás seguro que deseas eliminar esta notificación? Se borrarán todas las versiones de la misma.",

        //CONFIGURATIONS
        "CONFIGURATIONS": "Configuraciones",
        "SECURITY": "Seguridad",
        "MONTHS": "MESES",
        "CONFIGURATIONS_LABEL_FIELD_1": "Tiempo de vida máximo de una notificación",
        "CONFIGURATIONS_LABEL_FIELD_2": "Tiempo de intervalo mínimo entre notificaciones",
        "CONFIGURATIONS_LABEL_FIELD_3": "Horario Mínimo",
        "CONFIGURATIONS_LABEL_FIELD_4": "Evitar notificaciones durante el fin de semana",

        //FEEDBACK
        "FEEDBACK": "Opiniones",
        "MY_FEEDBACK": "Danos tu Opinión",
        "ENTER_MESSAGE_HERE": "Escriba el Mensaje",
        "WAS_USEFUL": "Fue útil",
        "IAM_NOT_CONVINCED": "No fué util",
        "FEEDBACK_LABEL1": "Danos tu opinión sobre la plataforma",
        "FEEDBACK_LABEL2": "Adjunta una imagen usando nuestra plataforma",
        "FEEDBACK_LABEL3": "Contanos tu experiencia usando nuestra plataforma",
        "SEND_FEEDBACK": "Envíar Opinión",
        "FEEDBACK_SENT": "Opninión enviada",
        "MESSAGE": "Mensaje",
        "USER": "Usuario",
        "SEARCH_BY_USER_OR_MESSAGE": "Buscar por usuario o mensaje",

        //TERMS AND CONDITIONS
        "TERMS_AND_CONDITIONS": "Términos y Condiciones",
        "DO_NOT_REMEMBER_AGAIN": "No recordar",
        "TO_REMEMBER_AGAIN": "OK",
        "WARNING_AND_USE_POLICY": "Advertencia y Política de Uso",

        "TERMS_AND_CONDITIONS_FRAME_URL": "https://myportfoliolanding.azurewebsites.net/terms-and-conditions",
        "WARNING_AND_USE_POLICY_FRAME_URL": "https://myportfoliolanding.azurewebsites.net/use-policy",

        //DECIMAL CUSTOMIZATION
        "DECIMAL_CUSTOMIZATION": "Personalización de decimales",
        "DECIMAL_CUSTOMIZATION_SUBTITLE": "Aquí podrás personalizar la cantidad de decimales a visualizar",
        "DECIMAL_CUSTOMIZATION_FIELD_DECIMALS": "Decimales",
        "ENTER_DECIMALs_HERE": "Ejemplo: 2",

        //TENENCIAS VALORIZADAS
        "VALUED_HOLDINGS": "Tenencias Valorizadas",
        "PORTFOLIO": "MiPortafolio",
        "TOTAL_VALUES": "MiPortafolio - Activos",
        "ACTIVE": "Activo",
        "QUANTITY": "Cantidad",
        "INSTRUMENT_QUANTITY_TOOLTIP": "Cantidad de contratos",
        "DIFFERENCE_QUANTITY_TOOLTIP": "Cantidad positivo equivale a compra y Cantidad negativa equivale a venta",
        "VALUED_ACTIVE": "Valor activo",
        "VALUED_ACTIVE_TOOLTIP":"Valor de referencia determinado por la Cámara Compensadora",
        "BALANCE": "Saldo",
        "DATE": "Fecha",
        "AGENT": "Agente",
        "ACCOUNT": "Cuenta",
        "NO_DATA_WITH_DATE": "No hay datos con la fecha",
        "ASSETS": "Activos",
        "FUTURES": "MiPortafolio - Posiciones",
        "FUTURES_AND_OPTIONS": "Futuros y opciones",
        "INSTRUMENT": "Instrumento",
        "PURPOSE": "Finalidad",
        "COUNTERPARTACCOUNT": "Cuentra Contraparte",
        "DIFFERENCE": "Diferencia",
        "DIFFERENCE_DIFFERENCE_TOOLTIP": "no tooltip",
        "TOTAL_DIFFERENCE": "Diferencia Total",
        "SETTLEMENT": "Precio Ajuste",
        "DIFFERENCE_SETTLEMENT_TOOLTIP": "Precio de mercado",
        "TOTAL_FUTURES": "MiPortafolio - Posiciones",
        "CANT_SHOW_DATA_ATM": "No se pueden mostrar los datos por el momento. Por favor, intente en unos minutos.",
        "PRICE": "Precio",
        "CURRENCY_SYMBOL": "Moneda",
        "PRICE_TOOLTIP":"Tenencia: precio de cotización de mercado. Margen: valor de referencia determinado por la Cámara Compensadora.",
        "DIFFERENCE_PRICE_TOOLTIP": "Precio promedio ponderado de adquisición",
        "VALUE": "Valor",
        "SWITCH_VIEW": "Cambiar Vista",
        "MY_INVESTMENTS": "MiPortafolio - Activos",
        "INSTRUMENT_VIEW": "Vista por Instrumento",
        "STOCK_VIEW": "Vista por Agente",
        "SELECT_VIEW": "Seleccione vista",
        "PERIOD": "Período",
        "CAPACITY": "Aforo",
        "WARRANTY_VALUE": "Valor garantía",
        "UNIT": "Unidad",

        //HISTORIAL
        "HISTORICAL": "Historial",
        "BUY": "Compra",
        "SELL": "Venta",
        "INCOME": "Ingreso",
        "EXIT": "Egreso",
		"SELECT_RANGE": "Seleccionar rango",
        "CANCEL_RANGE": "Seleccione una fecha",
        "FROM": "Desde",
        "TO": "Hasta",
        "NO_DATA_WITH_DATE_RANGE": "No hay datos con el rango",
        "TOTAL_RECORDS": "Total de Registros",
        "PAGE": "Página",
        "OF": "De",
        "PREVIOUS": "Anterior",
        "NEXT": "Siguiente",
        "REALOAD": "Recargar",
        "TAKER": "Tomador",
        "PLACER": "Colocador",
        "EXP_TAKER": "Vto. Tomador",
        "EXP_PLACER": "Vto. Colocador",
        "ANTICIPATED_TAKER": "Liq. Anticipada Tomador",
        "ANTICIPATED_PLACER": "Liq. Anticipada Colocador",
        "EXP_ANTICIPATED_TAKER": "Vto. Liq. Anticipada Tomador",
        "EXP_ANTICIPATED_PLACER": "Vto. Liq. Anticipada Colocador",
        "SUBSCRIPTION": "Suscripción",
        "RESCUE": "Rescate",
        "COUNTERPART_SUBSCRIPTION": "Contraparte Suscripción",
        "COUNTERPART_RESCUE": "Contraparte Rescate",
        "FORK": "Tenedor",
        "TRANSMITTER": "Emisor",
        "COUNTERPART_TRANSMITTER":"Contraparte Tenedor",
        "COUNTERPART_FORK": "Contraparte Emissor",

        // FAQ
        "HELP_TITLE": "Ayuda",
        "NO_FAQ_FOUND": "No se pudo acceder a la base de conocimientos",
        "FAQ_CREATE_TICKET": "Crear un nuevo Ticket",
        "BACK": "Volver",
        "SEND_FAQ": "Enviar",
        "FAQ_MESSAGE": "Mensaje",
        "FAQ_SENT": "Mensaje registrado con éxito. Nos contactaremos con Ud.",
        "FAQ_CREATE_TICKET": "Crear nuevo Ticket",
        "FAQ_CREATE_TICKET_TITLE": "Crear nuevo Ticket",
        "FAQ_CREATE_TICKET_CATEGORY_TITLE": "Ninguna de estas preguntas y respuestas me resulta útil",
        "FAQ_CREATE_TICKET_ANSWER_DESCRIPTION": "Aquí Ud. podrá crear un Ticket y contactarse con nuestro soporte.",
		
		// ACOUNT
		"ACOUNT_MESSAGE": "El mail registrado para estas cuentas no coincide con el que accediste a la aplicación actualmente. Para poder visualizar todas las cuentas en una misma pantalla, es necesario que solicites a tu agente la actualización del mail registrado en las mismas.",
		"CLEARING_MEMBERS": "Cuentas vinculadas",
		"RELATED_CLEARING_MEMBERS": "Cuentas que podrías vincular",

        // RESULT CANCELLATIONS
        "RESULT_CANCELLATIONS": "Resultado-Cancelaciones"
    }
}