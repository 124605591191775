import React from 'react';
import i18next from 'i18next';
import { toJS } from 'mobx';
import Spinner from '../shared/spinner';
import MessageSerivce from '../../services/utils/message.service';
import { Card, CardBody, Row, Col, Button } from 'reactstrap';
import NotificationsService from '../../services/notifications.service';
import InputFile from './../shared/inputFile';
import FileService from './../../services/file.service';
import UserSerivce from './../../services/user.service';
import AsyncSelect from 'react-select/async';
import { If } from 'react-if';
import Checkbox from './../shared/checkbox';
import { NotificationDeliveryStatus } from '../../app.values';

export default class NotificationsCreate extends React.Component {

    notificationId = null;
    isLoading = false;
    autocompleteInput = '';
    item = {
        filePath: null,
        text: '',
        name: '',
        users: [],
        sendOnlyPending: false,
        autoAcceptPreviousVersion: false,
    };

    constructor(props) {
        super(props);
    }

    componentWillMount() {
        this.notificationId = this.props.match.params.id;
        if (this.notificationId)
            _getItem.bind(this)(this.notificationId);
    }

    onSubmit = (e) => {
        e.preventDefault();

        this.isLoading = true;

        let item = toJS(this.item);
        item.usersId = item.users.map(item => item.id);

        let promise = !this.notificationId ?
            NotificationsService.create(item) :
            NotificationsService.newVersion(this.notificationId, {
                id: this.notificationId,
                name: item.name,
                text: item.text,
                filePath: item.filePath,
                usersId: item.usersId,
            });

        promise
            .then(() => {
                MessageSerivce.success(i18next.t('CREATED'))
                this.props.router.navigate('/notifications');
            })
            .finally(() => this.isLoading = false);
    }

    onFileChange = (file) => {
        if (file != null) {
            FileService
                .upload(file)
                .then(path => this.item.filePath = path);
        }
    }

    getAutocompleteOptions = (input, callback) => {
        if (!input || input.length < 2) {
            callback(null, { options: [], complete: true });
            return;
        }

        UserSerivce
            .find(input)
            .then(users => callback(null, { options: users, complete: true }))
            .catch(() => callback(null, { options: [], complete: true }));
    }

    onAutocompleteChange = (item) => {
        this.autocompleteInput = '';
        if (!this.item.users.find(u => u.id == item.id))
            this.item.users.push(item);
    }

    removeUser = (index) => {
        this.item.users.splice(index, 1);
    }

    sendOnlyPendingChange = (checked) => {
        if (checked) {
            this.item.sendOnlyPending = true;
            this.item.autoAcceptPreviousVersion = false;

            let allUsers = [];
            for (let v of this.item.versions) {
                allUsers = allUsers.concat(v.users.filter(u => u.notificationDeliveryStatus == NotificationDeliveryStatus.Pending));
            }

            this.item.users = allUsers;
        }
    }

    autoAcceptPreviousVersionChange = (checked) => {
        if (checked) {
            this.item.autoAcceptPreviousVersion = true;
            this.item.sendOnlyPending = false;

            let allUsers = [];
            for (let v of this.item.versions) {
                allUsers = allUsers.concat(v.users);
            }

            let filtered = allUsers.filter(user =>
                !this.item.users.find(u => u.id == user.id)
            );

            for (let f of filtered) {
                this.item.users.push(f);
            }
        }
    }

    render() {
        return (
            <Card className="notifications create">
                <CardBody>
                    <form className="configurations" onSubmit={this.onSubmit}>
                        <Row>
                            <Col lg="6">
                                <div className="form-group">
                                    <label>{i18next.t('NAME')}: </label>
                                    {
                                        !this.notificationId ?
                                            <input type="text"
                                                className="form-control"
                                                value={this.item.name}
                                                onChange={(e) => this.item.name = e.target.value}
                                                required /> :
                                            <div className="text-bold">{this.item.name}</div>
                                    }

                                </div>

                                {
                                    !!this.notificationId ?
                                        <React.Fragment>
                                            <div className="form-group">
                                                <Checkbox
                                                    id="sent"
                                                    className="mr-3"
                                                    label={i18next.t('SEND_ONLY_PEOPLE_NOT_ACCEPTED')}
                                                    checked={this.item.sendOnlyPending}
                                                    onChange={this.sendOnlyPendingChange} />
                                            </div>
                                            <div className="form-group">
                                                <Checkbox
                                                    id="pending"
                                                    className="mr-3"
                                                    label={i18next.t('AUTO_ACCEPT_OLD_VERSIONS')}
                                                    checked={this.item.autoAcceptPreviousVersion}
                                                    onChange={this.autoAcceptPreviousVersionChange} />
                                            </div>
                                        </React.Fragment> :
                                        null
                                }

                                <div className="form-group">
                                    <label>{i18next.t('TEXT')}: </label>
                                    <input type="text"
                                        className="form-control"
                                        value={this.item.text}
                                        onChange={(e) => this.item.text = e.target.value}
                                        required />
                                </div>
                                <div className="form-group">
                                    <label>{i18next.t('UPLOAD_FILE')}: </label>
                                    <InputFile onChange={(file) => this.onFileChange(file)} />
                                </div>

                            </Col>
                            <Col lg="6">
                                <div className="form-group">
                                    <label>{i18next.t('SEARCH_AND_ADD_USERS')}:</label>
                                    <AsyncSelect
                                        loadOptions={this.getAutocompleteOptions}
                                        value={this.autocompleteInput}
                                        onChange={this.onAutocompleteChange}
                                        noResultsText={i18next.t('NO_RESULTS')}
                                        placeholder={i18next.t('SEARCH_PLACEHOLDER')}
                                        labelKey="email"
                                        valueKey="id" />
                                </div>
                                <div className="form-group">
                                    <label>{i18next.t('SELECTED')}: ({this.item.users.length})</label>
                                    <ul className="scrollable-list">
                                        {this.item.users.map((item, index) =>
                                            <li key={index}>
                                                <span className="user-item-text">{item.email}</span>
                                                <Button
                                                    color="link"
                                                    className="lh-1 m-0 p-0 pull-right"
                                                    onClick={() => this.removeUser(index)}
                                                >
                                                    <i className="fa fa-times"></i>
                                                </Button>
                                            </li>
                                        )}
                                        <If condition={!this.item.users.length}>
                                            <li className="text-center">
                                                <span className="user-item-text text-muted">
                                                    {i18next.t('NONE_USER_SELECTED')}
                                                </span>
                                            </li>
                                        </If>
                                    </ul>
                                </div>
                            </Col>
                        </Row>

                        <div className="text-center mt-4">
                            <button type="submit" className="btn btn-sm btn-primary">
                                <i className="fa fa-envelope mr-2"></i>
                                {i18next.t('SAVE_AND_SEND')}
                            </button>
                        </div>
                    </form>
                </CardBody>
                {this.isLoading && <Spinner />}
            </Card>
        );
    }
}



function _getItem(id) {
    this.isLoading = true;

    NotificationsService
        .get(id)
        .then((item) => {
            this.item.name = item.name;
            this.item.versions = item.versions;
            this.sendOnlyPendingChange(true);
        })
        .finally(() => this.isLoading = false)
}

