import React from 'react';

export default class NoMatch extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="w-100 text-center" style={{ paddingTop: '7%' }}>
                <img src="/assets/img/error404.svg" height="250" />
            </div>
        )
    }
}