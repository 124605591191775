import React from 'react';

const COLORS = ['yellowgreen', 'darkred', 'coral', 'darkmagenta', 'blueviolet'];

export default class UserAvatar extends React.Component {
    constructor(props) {
        super(props);
    }

    getColor = () => {
        let index = this.props.index >= 0 ? this.props.index : Math.floor(Math.random() * COLORS.length);
        return COLORS[index % COLORS.length];
    }

    getFirstLetter = () => {
        return (this.props.username && this.props.username.length) ?
            this.props.username[0].toUpperCase() :
            '-';
    }

    render() {
        if (this.props.imgSrc)
            return <img src={this.props.imgSrc} className="img-circle mr-1" height="32" width="32" />;

        return (
            <span
                className="user-avatar"
                style={{
                    lineHeight: this.props.height || '32px',
                    height: this.props.height || '32px',
                    width: this.props.width || '32px',
                    backgroundColor: this.getColor()
                }}>
                {this.getFirstLetter()}
            </span>
        )
    }
}