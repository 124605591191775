import React from 'react';
import i18next from 'i18next';
import { Button } from 'reactstrap';
import { toJS } from 'mobx';
import { Link } from 'react-router-dom';
import Spinner from '../../shared/spinner';
import IdentitySerivce from '../../../services/indentity.service';
import MessageSerivce from './../../../services/utils/message.service';
import AuthChangeLangBtn from './../authChangeLangBtn';
import UtilsService from './../../../services/utils/utils.service';
import PasswordInfoModal from './passwordInfo.modal';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { faEyeSlash } from '@fortawesome/free-solid-svg-icons';
const eye = <FontAwesomeIcon icon={faEye} size="lg" color="#888888" />;
const eyeSlash = <FontAwesomeIcon icon={faEyeSlash} size="lg" color="#888888"/>;

class ChangePassword extends React.Component {

    constructor(props){
        super(props)

        this.state = {
            isLoading: false,

            hidePassword : true,
            hidePasswordRepeated : true,

            item: {
                email: '',
                newPassword: '',
                newPasswordRepeated: '',
                token: '',
                oldPassword: ''
            },
            passwordInfoModalConfig: {
                show: false
            }
        }
        this.togglePassword = this.togglePassword.bind(this);
        this.togglePasswordRepeated = this.togglePasswordRepeated.bind(this);
    }

    togglePassword = ()=>{
        this.setState({ hidePassword: !this.state.hidePassword })
    }

    togglePasswordRepeated = ()=>{
        this.setState({ hidePasswordRepeated: !this.state.hidePasswordRepeated })
    }

    componentDidMount() {
        const urlParams = new URLSearchParams(this.props.router.location.search);

        this.setState({
            item: {
                ...this.state.item,
                email: UtilsService.decodeURISecure(urlParams.get('email')),
                token: UtilsService.decodeURISecure(urlParams.get('token'))
            }
        })
    }

    onSubmit = (e) => {
        e.preventDefault();

        // Validate if the password is equal
        if(this.state.item.newPassword != this.state.item.newPasswordRepeated) {
            MessageSerivce.error(i18next.t('REPEATED_PASSWORD_NOT_MATCH'));

            return;
        }

        // Validate new password
        if(!this.validatePassword(this.state.item.newPassword)) {
            MessageSerivce.error(i18next.t('PASSWORD_NOT_COMPLETE_REQUERIMENTS'));

            return;
        }

        // Show loading & set oldpassword idem to newpassword
        this.setState({
            isLoading: true,
            item: {
                ...this.state.item,
                oldPassword: this.state.item.newPassword
            }
        })

        // Send to API
        IdentitySerivce
            .changePassword(toJS(this.state.item))
            .then(() => { //Success
                MessageSerivce.success(i18next.t('PASSWORD_CHANGED'));

                this.props.router.navigate('/login');
            })
            .catch((res) => { //Error
                if(res.errorCodes && res.errorCodes.length > 0) {
                    res.errorCodes.forEach(code => {
                        if(code == "INVALID_CREDENTIALS") { // Invalid Credentials to Exipred Token
                            code = "EXPIRED_TOKEN";
                        }

                        MessageSerivce.error(i18next.t(code));
                    });
                } else if(res.errorMessages) {
                    res.errorMessages.forEach(msg => MessageSerivce.error(msg));
                }
            })
            .finally(() => this.setState({isLoading: false}));
    }

    validatePassword(password) {
        // Check length
        if(password.length < 6) {
            return false;
        }

        let regexList = [
            ///^(?=.*[a-z])/g,
            /^(?=.*[A-Z])/g,
            /^(?=.*[0-9])/g,
            /^((?!\&).)*$/g,
            
            ///^(?=.*[!@#\$%\^&\*+_\-/])/g,
        ];

        var regexCheckedCount = 0;

        regexList.forEach((_regex) => {
            // Check by regex
            var regex = new RegExp(_regex);
            if(regex.test(password)) {
                regexCheckedCount++;
            }
        });

        // Check if have 3 or more regex checked
        if(regexCheckedCount >= 3) {
            return true;
        }

        return false;
    }

    showPasswordInfoModal = () => {
        this.setState({
            passwordInfoModalConfig: {
                ...this.state.passwordInfoModalConfig,
                show: true
            }
        });
    }

    render() {
        return (
            <div className="auth">
                <div className="auth-container">
                    <div className="text-center mb-2">
                        <img height="48" src="/assets/img/logo.png" />
                    </div>
                    <div className="card">
                        <div className="card-body">
                            <form onSubmit={this.onSubmit} autoComplete="off">
                                <h3 className="text-muted text-center mb-4">{i18next.t('CHANGE_PASSWORD')}</h3>
                                <div className="form-group">
                                    <label className="mr-2">{i18next.t('EMAIL')}: </label>
                                    <strong>{this.state.item.email}</strong>
                                </div>
                                <div className="form-group">
                                    <label>{i18next.t('NEW_PASSWORD')}</label>
                                    <input type={this.state.hidePassword ? "password" : "text"}
                                        className="form-control"
                                        value={this.state.item.newPassword}
                                        onChange={(e) => {
                                            this.setState({
                                                item: {
                                                    ...this.state.item,
                                                    newPassword: e.target.value
                                                }
                                            });
                                        }}
                                        autoCorrect="off"
                                        required />
                                    <button type="button"
                                        onClick={this.togglePassword}
                                        className="password-button">
                                        {this.state.hidePassword ? eyeSlash : eye }
                                    </button>
                                </div>
                                <div className="form-group">
                                    <label>{i18next.t('NEW_PASSWORD_REPEATED')}</label>
                                    <input type={this.state.hidePasswordRepeated ? "password" : "text"}
                                        className="form-control"
                                        value={this.state.item.newPasswordRepeated}
                                        onChange={(e) => {
                                            this.setState({
                                                item: {
                                                    ...this.state.item,
                                                    newPasswordRepeated: e.target.value
                                                }
                                            });
                                        }}
                                        autoCorrect="off"
                                        required />
                                    <button type="button"
                                        onClick={this.togglePasswordRepeated}
                                        className="password-button">
                                        {this.state.hidePasswordRepeated ? eyeSlash : eye }
                                    </button>
                                </div>
                                <button type="submit" className="btn btn-info btn-lg w-100">
                                    {i18next.t('CHANGE')}
                                </button>
                                <div className="mt-3 text-center">
                                    <Button size="sm" color="link" onClick={this.showPasswordInfoModal}>
                                        {i18next.t('HOW_TO_CHOOSE_PASSWORD_LINK')}
                                    </Button>
                                </div>
                                <div className="mt-3 text-center">
                                    <Link to="/login" className="w-100">
                                        {i18next.t('BACK_TO_LOGIN')}
                                    </Link>
                                </div>
                            </form>
                            <div className="text-center mt-3">
                                <AuthChangeLangBtn onChange={() => this.forceUpdate()} />
                            </div>
                        </div>
                    </div>
                    {this.state.isLoading && <Spinner />}
                </div>

                {this.state.passwordInfoModalConfig.show && <PasswordInfoModal config={this.state.passwordInfoModalConfig} />}
            </div>
        );
    }
};

export default UtilsService.withRouter(ChangePassword);