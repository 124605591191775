import CoreService from './utils/core.service';

const route = 'Users';

const UserSerivce = {
    find: (filter) => CoreService.get(`${route}/find/${filter}`),
    resend: (email) => CoreService.post(`${route}/email/verification/send`, JSON.stringify(email)),
    changeLanguage: (lang) => CoreService.post(`${route}/changeLanguage/${lang}`, null),
    registerFirstLogin: ( username, password ) => CoreService.post(`Users/register/first-login`, { username, password }),
};

export default UserSerivce;