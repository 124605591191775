export const TranslateEN = {
    translation: {
        //GENERAL FOR ALL SCREENS
        "ERROR_MSG": "An unexpected error has occurred on the server. Please try to perform the action later.",
        "ARE_YOU_SURE_TO_DELETE": "Are you sure to delete?",
        "DELETED": "Deleted successfully",
        "SAVED": "Saved successfully",
        "CREATED": "Created successfully",
        "REQUIRED_FIELDS": "Required Fields",
        "COMPLETE_ALL_FIELDS": "Please, complete all fields",
        "OK": "Ok",
        "YES": "Yes",
        "NO": "No",
        "YOU": "You",
        "DELETE": "Delete",
        "ADD": "Add",
        "NEW": "New",
        "UPLOAD": "Upload",
        "CANCEL": "Cancel",
        "SAVE": "Save",
        "EDIT": "Edit",
        "VIEW": "View",
        "CHANGE": "Change",
        "SEARCH": "Search",
        "ALL": "All",
        "CONFIRMATION": "Confirmation",
        "DOWNLOAD": "Download",
        "DOWNLOAD_XLSX": "Request report",
        "DOWNLOAD_XLSX_ERROR": "An error occurred while creating the export. Please try again.",
        "NO_RESULTS": "No results",
        "NO_DATA": "No Data",
        "FILE": "File",
        "LINK": "Link",
        "TYPE": "Type",
        "TITLE": "Title",
        "NAME": "Name",
        "PAGE_NOT_FOUND": "Page not Found",
        "CLEAR_FILTERS": "Clear Filters",
        "CLEAR": "Delete",
        "NO_ACCOUNTS_FOUND": "No accounts was found",
        "SEUO": "E & OE",
        "SEUO_TITLE": "Except Error or Omission",
        "WARNING": "Warning",
        "AGREE": "Agree",
        "DECLINE": "Decline",
        "CONTINUE": "Continue",

        // EXPORT MESSAGES
        "EXPORT_QUEUE": "Report request generated",
        "EXPORT_QUEUE_MESSAGE": "We have generated your request to send a report. It will be sent to your email address in the next few hours. Please check your inbox and spam folder.", 

        //ERROR MESSAGES
        "INVALID_CREDENTIALS": "Invalid credentials.",
        "PASSWORD_ALREADY_EXISTS": "The password must be different from the previous.",

        //HEADER
        "LOGOUT": "Log Out",
        "LOGIN_AS": "Login as",
        "ADD_ACCOUNT": "Add Account",

        //MENU
        "MY_DEVICES": "My devices",
        "ADMINISTRATION": "Management",
        "MY_PORTFOLIO": "My Portfolio",
        "MY_ACCOUNTS": "My Accounts",
        "HELP": "Help",

        //LOGIN
        "LOGIN": "Log in",
        "USER_OR_PASSWORD_INVALID": "Invalid credentials. Disabled user or expired password. For security reasons you will need to reset the password.",
        "RESET_PASSWORD": "Forgot Password / I want to reset my password",
        "EMAIL": "Email",
        "PASSWORD": "Password",
        "ARE_YOU_ADMIN": "Are you Admin? Click Here",
        "REGISTER_FIRST_ACCESS": "First access? Click Here",
        "REMEMBER_USER": "Keep session started",

        //RESET
        "RESET_PASSWORD_INFO_TEXT": "If you forgot your password, write your email and we will send you a link with the information to change it",
        "BACK_TO_LOGIN": "Back to login",
        "SEND_LINK": "Send Link",
        "WE_SENT_AN_EMAIL": "We sent you an email",
        "CHECK_YOUR_EMAIL_INBOX": "Check your email inbox. Remember that this could take few minutes",
        "HAVENT_YOU_RECEIVED_AN_EMAIL": "Haven't you recieved an email?",

        //WELCOME
        "WELCOME_TITLE": "Welcome to Argentina Clearing",
        "WELCOME_INFO_TEXT": "Please enter your email and temporary password to continue the registration process",
        "WELCOME_SUBMIT": "Continue",

        //CHANGE PASSWORD
        "CHANGE_PASSWORD": "Change password",
        "OLD_PASSWORD": "Old password",
        "NEW_PASSWORD": "New password",
        "NEW_PASSWORD_REPEATED": "Repeat the new password",
        "CHANGE": "Change",
        "PASSWORD_CHANGED": "Password changed",

        "PASSWORD_NOT_COMPLETE_REQUERIMENTS": "The password does not complete with any of the requirements.",
        "REPEATED_PASSWORD_NOT_MATCH": "Repeated password does not match.",
        "EXPIRED_TOKEN": "This email was already used before. Please request again",

        "HOW_TO_CHOOSE_PASSWORD_LINK": "How to choose the password?",
        "HOW_TO_CHOOSE_PASSWORD_TITLE": "How to choose the password?",
        "HOW_TO_CHOOSE_PASSWORD_DESCRIPTION_1": "The password must be at least 8 characters,",
        "HOW_TO_CHOOSE_PASSWORD_DESCRIPTION_2": "be different from the last six previous and include at least three of the following options:",
        "HOW_TO_CHOOSE_PASSWORD_DESCRIPTION_3": "one or more uppercase letters,",
        "HOW_TO_CHOOSE_PASSWORD_DESCRIPTION_4": "one or more lowercase letters,",
        "HOW_TO_CHOOSE_PASSWORD_DESCRIPTION_5": "one or more numeric digits (0 - 9),",
        "HOW_TO_CHOOSE_PASSWORD_DESCRIPTION_6": "one or more non-alphanumeric special characters (for example -, *, / or +)",

        "HOW_TO_CHOOSE_PASSWORD_DESCRIPTION_1": "Passwords must have:",
        "HOW_TO_CHOOSE_PASSWORD_DESCRIPTION_2": "* Minimum 6 characters in length.",
        "HOW_TO_CHOOSE_PASSWORD_DESCRIPTION_3": "* A capital letter [A-Z].",
        "HOW_TO_CHOOSE_PASSWORD_DESCRIPTION_4": "* A numerical character [0-9].",
        "HOW_TO_CHOOSE_PASSWORD_DESCRIPTION_5": "* It must be different from the last one used.",
        "HOW_TO_CHOOSE_PASSWORD_DESCRIPTION_6": "* It should not include the special character &.",

        //MIS DISPOSITIVOS
        "NUMBER": "Number",
        "CODE": "Code",
        "LAST_LOGIN": "Last Login",
        "ARE_YOU_SURE_TO_LOGOUT": "Are you sure to log out this device?",

        //NOTIFICATIONS
        "NOTIFICATIONS": "Notifications",
        "NOTIFICATIONS_CREATE_NEW_VERSION": "Notifications: Create new version",
        "NOTIFICATIONS_CREATE": "Notifications: Create",
        "NOTIFICATIONS_EDIT": "Notifications: Edit",
        "CREATE_NOTIFICATION": "Create Notification",
        "VIEW_NOTIFICATION": "View Notification",
        "SHOW_ONLY_LAST_VERSIONS": "Show only last versions",
        "VERSION": "Version",
        "NUMBER_SENT": "Number of sent notifications",
        "NUMBER_ACCEPTED_PEOPLE": "Number of people accepted",
        "TEXT": "Text",
        "UPLOAD_FILE": "Upload File",
        "SEARCH_AND_ADD_USERS": "Search and add Users",
        "SEARCH_PLACEHOLDER": "Write user email (2 letters)",
        "SELECTED": "Selected",
        "NONE_USER_SELECTED": "None users selected",
        "SAVE_AND_SEND": "Save and Send",
        "NEW_VERSION": "New version",
        "EXPIRATION_DATE": "Expirantion Date",
        "NO_FILE": "No File",
        "SENT": "Sent",
        "PENDING": "Pending",
        "ACCEPTED": "Accepted",
        "SEND_ONLY_PEOPLE_NOT_ACCEPTED": "Send only to people that not accepted the notification",
        "AUTO_ACCEPT_OLD_VERSIONS": "Auto-accept previous version and send to all",
        "ARE_YOU_SURE_TO_DELETE_NOTIFICATIONS": "Are you sure to delete this notifications? All versions will be deleted.",

        //CONFIGURATIONS
        "CONFIGURATIONS": "Configurations",
        "SECURITY": "Security",
        "MONTHS": "Months",
        "CONFIGURATIONS_LABEL_FIELD_1": "Maximum life of a notification",
        "CONFIGURATIONS_LABEL_FIELD_2": "Minimum interval time between notifications",
        "CONFIGURATIONS_LABEL_FIELD_3": "Minimum Time",
        "CONFIGURATIONS_LABEL_FIELD_4": "Avoid notifications during the weekend",

        //FEEDBACK
        "FEEDBACK": "Feedback",
        "MY_FEEDBACK": "Give us your feedback",
        "ENTER_MESSAGE_HERE": "Type message here",
        "WAS_USEFUL": "Was userful",
        "IAM_NOT_CONVINCED": "I'm not convinced",
        "FEEDBACK_LABEL1": "Give us your opinion about the platform",
        "FEEDBACK_LABEL2": "Upload an image using our platform",
        "FEEDBACK_LABEL3": "Tell us about your experience using our platform",
        "SEND_FEEDBACK": "Send Feedback",
        "FEEDBACK_SENT": "Feedback was sent",
        "MESSAGE": "Message",
        "USER": "User",
        "SEARCH_BY_USER_OR_MESSAGE": "Search by user or message",

        //TERMS AND CONDITIONS
        "TERMS_AND_CONDITIONS": "Terms and Conditions",
        "DO_NOT_REMEMBER_AGAIN": "Do not remember",
        "TO_REMEMBER_AGAIN": "OK",
        "WARNING_AND_USE_POLICY": "Warning and Use Policy",

        "TERMS_AND_CONDITIONS_FRAME_URL": "https://myportfoliolanding.azurewebsites.net/terms-and-conditions-en",
        "WARNING_AND_USE_POLICY_FRAME_URL": "https://myportfoliolanding.azurewebsites.net/use-policy-en",

        //DECIMAL CUSTOMIZATION
        "DECIMAL_CUSTOMIZATION": "Decimal Customization",
        "DECIMAL_CUSTOMIZATION_SUBTITLE": "Here you can customize the decimals places to display",
        "DECIMAL_CUSTOMIZATION_FIELD_DECIMALS": "Decimals",
        "ENTER_DECIMALs_HERE": "Ex: 2",

        //TENENCIAS VALORIZADAS
        "VALUED_HOLDINGS": "Valued Holdings",
        "PORTFOLIO": "Position",
        "TOTAL_VALUES": "MiPortafolio - Assets",
        "ACTIVE": "Asset",
        "QUANTITY": "Quantity",
        "INSTRUMENT_QUANTITY_TOOLTIP": "Number of contracts",
        "DIFFERENCE_QUANTITY_TOOLTIP": "Positive quantity is equivalent to purchase and negative quantity is equivalent to sale.",
        "VALUED_ACTIVE": "Value",
        "VALUED_ACTIVE_TOOLTIP":"Reference value stated by the Clearing House.",
        "BALANCE": "Balance",
        "DATE": "Date",
        "AGENT": "Broker",
        "ACCOUNT": "Account",
        "NO_DATA_WITH_DATE": "No data with date",
        "ASSETS": "Assets",
        "FUTURES": "MiPortafolio - Positions",
        "FUTURES_AND_OPTIONS": "Futures and options",
        "INSTRUMENT": "Instrument",
        "PURPOSE": "Purpose",
        "COUNTERPARTACCOUNT": "Counterpart Account",
        "DIFFERENCE": "Difference",
        "DIFFERENCE_DIFFERENCE_TOOLTIP": "no tooltip",
        "TOTAL_DIFFERENCE": "Total Difference",
        "SETTLEMENT": "Settlement price",
        "DIFFERENCE_SETTLEMENT_TOOLTIP": "Market price",
        "TOTAL_FUTURES": "MiPortafolio - Positions",
        "CANT_SHOW_DATA_ATM": "We can't show the data at the moment. Please, try later",
        "PRICE": "Price",
        "CURRENCY_SYMBOL": "Currency",
        "PRICE_TOOLTIP":"Holding: market price. Margin: reference value stated by the Clearing House.",
        "DIFFERENCE_PRICE_TOOLTIP": "Weighted averaged purchase price",
        "VALUE": "Value",
        "SWITCH_VIEW": "Swicth View",
        "MY_INVESTMENTS": "MiPortafolio - Assets",
        "INSTRUMENT_VIEW": "Instrument View",
        "STOCK_VIEW": "Broker View",
        "SELECT_VIEW": "Select view",
        "PERIOD": "Period",

        //HISTORIAL
        "HISTORICAL": "Historical",
        "BUY": "Buy",
        "SELL": "Sell",
        "INCOME": "Income",
        "EXIT": "Exit",
        "SELECT_RANGE": "Select Range",
        "CANCEL_RANGE": "Pick a sigle Date",
        "FROM": "From",
        "TO": "To",
        "NO_DATA_WITH_DATE_RANGE": "No data with range",
        "TOTAL_RECORDS": "Total Records",
        "PAGE": "Page",
        "OF": "Of",
        "PREVIOUS": "Previous",
        "NEXT": "Next",
        "REALOAD": "Reload",
        "TAKER": "Holder",
        "PLACER": "Seller",
        "EXP_TAKER": "Holder expiration",
        "EXP_PLACER": "Seller expiration",
        "ANTICIPATED_TAKER": "Advance Holder settlement ",
        "ANTICIPATED_PLACER": "Advance Seller settlement",
        "EXP_ANTICIPATED_TAKER": "Advance Holder Settlement Expiration  ",
        "EXP_ANTICIPATED_PLACER": "Advance Seller Settlement Expiration",
        "SUBSCRIPTION": "Subscription",
        "RESCUE": "Rescue",
        "COUNTERPART_SUBSCRIPTION": "Counterpart Subscription",
        "COUNTERPART_RESCUE": "Counterpart Rescue",
        "FORK": "Holder",
        "TRANSMITTER": "Issuer",
        "COUNTERPART_TRANSMITTER":"Counterpart Issuer",
        "COUNTERPART_FORK": "Conteurpart Holder",

        // FAQ
        "HELP_TITLE": "Help",
        "NO_FAQ_FOUND": "Could not access the knowledge base",
        "FAQ_CREATE_TICKET": "Create a new Ticket",
        "BACK": "Back",
        "SEND_FAQ": "Send",
        "FAQ_MESSAGE": "Message",
        "FAQ_SENT": "Message registered successfully. We will contact you.",
        "FAQ_CREATE_TICKET": "Create new Ticket",
        "FAQ_CREATE_TICKET_TITLE": "Create new Ticket",
        "FAQ_CREATE_TICKET_CATEGORY_TITLE": "None of these questions and answers are helpful to me",
        "FAQ_CREATE_TICKET_ANSWER_DESCRIPTION": "Here you can create a Ticket and contact our support.",
		"CLOSE": "Close",
		"DELETE_DEVICE": "Delete device",

		// ACOUNT
		"ACOUNT_MESSAGE": "The registered mail for this account doesn't match with the one used to access this aplication. To visualize all the accounts in the same screen, it is necessary to ask your broker to update the email.",
		"CLEARING_MEMBERS": "Accounts",
		"RELATED_CLEARING_MEMBERS": "Related Accounts",
    }
}