import React from 'react';
import i18next from 'i18next';
import { Link } from 'react-router-dom';

export default class EmailSent extends React.Component {

    constructor(props) {
        super(props);
    }

    onResendClick = () => {
        this.props.onResendClick && this.props.onResendClick();
    }

    render() {
        return (
            <div className="text-center">
                <h5 className="text-success-dark">
                    {i18next.t('WE_SENT_AN_EMAIL')}
                </h5>
                <h6 className="text-muted">
                    {i18next.t('CHECK_YOUR_EMAIL_INBOX')}
                </h6>
                <div className="my-3">
                    <img src="/assets/img/send-email.svg" height="130" />
                </div>
                <Link to="/reset" className="w-100" onClick={this.onResendClick}>
                    {i18next.t('HAVENT_YOU_RECEIVED_AN_EMAIL')}
                </Link>
            </div>
        );
    }
};