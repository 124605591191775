import React from 'react';
import i18next from 'i18next';
import TermsAndConditionsWarningModal from './termsAndConditionsWarning.modal';

import SEUO from '../shared/seuo';

export default class TermsAndConditionsFooter extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            warningModalConfig: {
                show: false
            }
        };
    }

    showWarningModal = () => {
        this.setState({
            warningModalConfig: {
                ...this.state.warningModalConfig,
                show: true
            }
        })
    }

    render() {
        return (
            <React.Fragment>
                <div style={{
                    position: "fixed",
                    background: "#f1f1f1",
                    color: "#444",
                    bottom: "2px",
                    right: '15px',
                    left: 0,
                }}>
                    <div className="row">
                        <div className="col-12 text-right">
                            <span title={i18next.t('WARNING')} onClick={this.showWarningModal} style={{
                                cursor: 'pointer'
                            }}>
                                {i18next.t('WARNING')}
                            </span>

                            {/* <SEUO /> */}
                            <span title={i18next.t('SEUO_TITLE')} className="ml-3">
                                {i18next.t('SEUO')}
                            </span>
                        </div>
                    </div>
                </div>
                {this.state.warningModalConfig.show && <TermsAndConditionsWarningModal config={this.state.warningModalConfig} />}
            </React.Fragment>
        )
    }
}