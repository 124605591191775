import React from 'react';
import i18next from 'i18next';
import { toJS } from 'mobx';
import { Row, Col, Card, CardBody, CardHeader, Button, Collapse } from 'reactstrap';
import Spinner from '../shared/spinner';
import FAQSerivce from '../../services/faq.service';
import BackOfficeSerivce from './../../services/backOffice.service';
import DOMPurify from 'dompurify';
import NoData from '../shared/noData';
import UtilsService from './../../services/utils/utils.service';
import MessageSerivce from './../../services/utils/message.service';

class Help extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            showBackOfficeError: false,

            agents: [],
            accounts: [],

            currentPage: 'contents',
            items: [],

            currentCategory: null,
            currentCategoryItemsIsUseful: [],

            formItem: {
                agent: '',
                account: '',
                message: ''
            }
        }
    }

    componentDidMount() {
        this._getItems();

        // Hide celphone banner
        if(document.getElementById('smartbanner')) {
            document.getElementById('smartbanner').style.display = 'none';
        }

        let urlParams = new URLSearchParams(this.props.router.location.search);
        let hide_navigation = UtilsService.decodeURISecure(urlParams.get('hide_navigation'));
        if(hide_navigation == '1') {
            document.getElementById('header').classList.add('hide-nav');
            document.getElementById('sidebar').classList.add('hide-nav');

            document.getElementById('main').classList.add('hide-nav');
        }
    }

    _getItems() {
        this.setState({
            isLoading: true,
            showBackOfficeError: false
        })

        // Get contents
        FAQSerivce
            .getContents()
            .then((data) => {
                this.setState({
                    items: data
                });
            })
            .catch((error) => {
                if (error.errorCodes && error.errorCodes.indexOf('BACK_OFFICE_ERROR') >= 0) {
                    this.setState({
                        showBackOfficeError: true
                    });
                }
            })
            .finally(() => this.setState({isLoading: false}))

        // Get ticket data (dataprovider)
        BackOfficeSerivce
            .getClearingMenbersOld()
            .then((data) => {
                // Parse businessUnits > agents
                let accounts = [];
                data.forEach((agent) => {
                    agent.accounts.forEach((account) => {
                        accounts.push({
                            code: account.agentName,
                            displayText: `${account.agentName} - ${agent.name} - ${account.accountName}`,
                            name: account.accountName,
                            agent: {
                                code: agent.identifier,
                                name: agent.name
                            }
                        })
                    })
                });

                // Set dataprovider on memory
                this.setState({
                    accounts: accounts
                })
            })
            .catch((error) => {
                if (!error || (error.errorCodes && error.errorCodes.indexOf('BACK_OFFICE_ERROR') >= 0)) {
                    this.setState({
                        showBackOfficeError: true
                    });
                }
            })
            .finally(() => console.log('finish'))
    }

    onThumbUp(id) {
        // Send
        FAQSerivce
            .voteAnswer(toJS({
                id: id,
                response: 'positive'
            }))
            .catch((error) => {
                if (error.errorCodes && error.errorCodes.indexOf('BACK_OFFICE_ERROR') >= 0) {
                    this.setState({
                        showBackOfficeError: true
                    });
                }
            });

        let currentCategoryItemsIsUseful = this.state.currentCategoryItemsIsUseful;
        currentCategoryItemsIsUseful[id] = true;

        // Set state
        this.setState({
            currentCategoryItemsIsUseful: currentCategoryItemsIsUseful
        });
    }

    onThumbDown(id) {
        // Send
        FAQSerivce
            .voteAnswer(toJS({
                id: id,
                response: 'negative'
            }))
            .catch((error) => {
                if (error.errorCodes && error.errorCodes.indexOf('BACK_OFFICE_ERROR') >= 0) {
                    this.setState({
                        showBackOfficeError: true
                    });
                }
            })

            let currentCategoryItemsIsUseful = this.state.currentCategoryItemsIsUseful;
            currentCategoryItemsIsUseful[id] = false;
    
            // Set state
            this.setState({
                currentCategoryItemsIsUseful: currentCategoryItemsIsUseful
            });
    }

    goToCreateTicket = (e) => {
        e.preventDefault();

        // Go to form page
        this.setState({
            currentPage: 'form',
            formItem: {
                agent: '',
                account: '',
                message: ''
            }
        });
    }

    onSubmit = (e) => {
        e.preventDefault();

        // Get account
        let account = this.state.accounts.find((_) => _.code == this.state.formItem.account)

        this.setState({
            isLoading: true
        });

        // Data
        let data = {
            clearingMemberCode: account ? account.agent.code : null,
            accountCode: account ? account.code : null,
            description: this.state.formItem.message,
        }

        // Create ticket
        FAQSerivce
            .createTicket(toJS(data))
            .then(() => {
                // Show successful message
                MessageSerivce.success(i18next.t('FAQ_SENT'));
               
                // Go home (contents)
                this.setState({
                    currentPage: 'contents'
                });
            })
            .catch((error) => {
                MessageSerivce.error(i18next.t('ERROR_MSG'));
            })
            .finally(() => {
                this.setState({
                    isLoading: false
                });
            })
    }

    back = () => {
        if(this.state.currentPage == 'form') {
            // Go to contents
            this.setState({
                currentPage: 'contents'
            });
        } 
    }

    contents() {
        if(this.state.currentPage != 'contents') { return; }

        return (<div>
            
            {this.state.items.map((item, i) => 
                <Card className="mb-2" key={i}>
                    <CardHeader
                        className="cursor-pointer"
                        onClick={() => this.toggleCollapse(item.categoryId)}
                    >
                        <Row className="align-items-center">
                            <Col xs="10">
                                {item.categoryName}
                            </Col>
                            <Col className="text-right">
                                <Button className="m-0 p-0" color="link" size="sm">
                                    <i className={`fa fa-chevron-${this.isCollapseOpen(item.categoryId) ? 'up' : 'down'}`}></i>
                                </Button>
                            </Col>
                        </Row>
                    </CardHeader>
                    <Collapse isOpen={this.isCollapseOpen(item.categoryId)}>
                        <CardBody className="p-3">

                            {this.categoryContents(item)}
                        
                        </CardBody>
                    </Collapse>
                </Card>
            )}

            {this.state.items.length > 0 ?
                <Card className="mb-2">
                    <CardHeader
                        className="cursor-pointer"
                        onClick={() => this.toggleCollapse(-1)}
                    >
                        <Row className="align-items-center">
                            <Col xs="10">
                                {i18next.t('FAQ_CREATE_TICKET_CATEGORY_TITLE')}
                            </Col>
                            <Col className="text-right">
                                <Button className="m-0 p-0" color="link" size="sm">
                                    <i className={`fa fa-chevron-${this.isCollapseOpen(-1) ? 'up' : 'down'}`}></i>
                                </Button>
                            </Col>
                        </Row>
                    </CardHeader>
                    <Collapse isOpen={this.isCollapseOpen(-1)}>
                        <CardBody className="p-3">

                            <a href="#" onClick={this.goToCreateTicket}>
                                {i18next.t('FAQ_CREATE_TICKET')}
                            </a>
                        
                        </CardBody>
                    </Collapse>
                </Card>
            : null}

        </div>)
    }

    categoryContents(item) {
        return item.answers.map((answer, i) => 
            <Card className="mb-2">
                <CardHeader
                    className="cursor-pointer"
                    onClick={() => this.toggleCollapse(item.categoryId, i)}
                >
                    <Row className="align-items-center">
                        <Col xs="10">
                            {answer.name}
                        </Col>
                        <Col className="text-right">
                            <Button className="m-0 p-0" color="link" size="sm">
                                <i className={`fa fa-chevron-${this.isCollapseOpen(item.categoryId, i) ? 'up' : 'down'}`}></i>
                            </Button>
                        </Col>
                    </Row>
                </CardHeader>
                <Collapse isOpen={this.isCollapseOpen(item.categoryId, i)}>
                    <CardBody className="p-3">
                        
                        <div className="content" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(answer.kbdocumentBody)}}></div>

                        <Row className="text-center mb-3 mx-0 clickeable">
                            <Col xs="6" className="pl-0">
                                <Card onClick={(e) => this.onThumbUp(answer.id)}
                                    className={this.state.currentCategoryItemsIsUseful[answer.id] ? 'text-success shadow' : 'text-muted'}>
                                    <CardBody>
                                        <i className="d-block fa fa-thumbs-up font-size-48"></i>
                                        <small>{i18next.t('WAS_USEFUL')}</small>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col xs="6" className="pr-0">
                                <Card onClick={(e) => this.onThumbDown(answer.id)}
                                    className={this.state.currentCategoryItemsIsUseful[answer.id] == false ? 'text-danger shadow' : 'text-muted'}>
                                    <CardBody>
                                        <i className="d-block fa fa-thumbs-down font-size-48"></i>
                                        <small>{i18next.t('IAM_NOT_CONVINCED')}</small>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    
                    </CardBody>
                </Collapse>
            </Card>
        )
    }

    form() {
        if(this.state.currentPage != 'form') { return; }

        return (
            <div>
                <Card className="shadow mb-3">
                    <CardHeader className="py-2 position-relative" onClick={this.back}>
                        <strong className="text-primary">{i18next.t('FAQ_CREATE_TICKET_TITLE')}</strong>

                        <a href="#" className="back-btn" onClick={(e) => e.preventDefault()}>{i18next.t('BACK')}</a>
                    </CardHeader>
                </Card>

                <form onSubmit={this.onSubmit}>
                    <span className="d-block font-size-14 mb-2" style={{ whiteSpace: 'normal' }}>
                        {i18next.t('ACCOUNT')}
                    </span>
                    <select className="form-control"
                        value={this.state.formItem.account}
                        onChange={(e) => {
                            this.setState({
                                formItem: {
                                    ...this.state.formItem,
                                    account: e.target.value
                                }
                            })
                        }}
                        required
                    >
                        <option value=''>{i18next.t('ACCOUNT')}</option>
                        {this.state.accounts.map((item, index) =>
                            <option key={index} value={item.code}>{item.displayText}</option>
                        )}
                    </select>

                    <span className="d-block font-size-14 mb-2" style={{ whiteSpace: 'normal' }}>
                        {i18next.t('FAQ_MESSAGE')}:
                    </span>
                    <textarea
                        placeholder={`${i18next.t('ENTER_MESSAGE_HERE')}...`}
                        className="form-control"
                        rows="5"
                        value={this.state.formItem.message}
                        onChange={(e) => {
                            this.setState({
                                formItem: {
                                    ...this.state.formItem,
                                    message: e.target.value
                                }
                            })
                        }}
                        required />

                    <div className="mt-3 text-center">
                        <Button type="submit" color="primary" className="w-100">
                            {i18next.t('SEND_FAQ')}
                        </Button>
                    </div>
                </form>
            </div>
        )
    }

    render() {
        return (
            <div className="help">
                {this.contents()}
                {this.form()}

                {
                    (!this.state.items.length && !this.state.isLoading) ?
                        <NoData msg={i18next.t('NO_FAQ_FOUND')} /> :
                        null
                }

                {this.state.isLoading && <Spinner />} 
            </div>
        );
    }

    getTodayDate = () => {
        let d = new Date();
        d.setHours(0);
        d.setMinutes(0);
        d.setSeconds(0);
    
        return d; 
    }

    toggleCollapse = (index1, index2 = null) => {
        let index = `${index1}${index2}`;

        if(index2 == null) {
            this.setState({
                openedIndex: (this.state.openedIndex != index) ? index : null
            });
        } else {
            this.setState({
                openedIndexSecondLevel: (this.state.openedIndexSecondLevel != index) ? index : null
            });
        }
    }

    isCollapseOpen(index1, index2 = null) {
        let index = `${index1}${index2}`;

        if(index2 == null) {
            return this.state.openedIndex == index;
        } else {
            return this.state.openedIndexSecondLevel == index;
        }
    }

}

export default UtilsService.withRouter(Help);