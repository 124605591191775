import React from 'react';
import { Route, Navigate, Routes, Redirect } from 'react-router-dom';
import i18next from 'i18next';
import { Card, CardBody } from 'reactstrap';

/* components */
import Header from './components/header/header';
import IdentitySerivce from './services/indentity.service';
import Dispatcher from './components/dispatcher/dispatcher';
import Sidebar from './components/sidebar/sidebar';
import Devices from './components/devices/devices';
import Holdings from './components/holdings/holdings';
import NoMatch from './components/shared/noMatch';
import Historical from './components/historical/historical';
import Configurations from './components/configurations/configurations';
import Notifications from './components/notifications/notifications';
import NotificationsCreate from './components/notifications/notifications.create';
import NotificationsEdit from './components/notifications/notifications.edit';
import Login from './components/auth/login/login';
import ResetPassword from './components/auth/resetPassword/resetPassword';
import ChangePassword from './components/auth/changePassword/changePassword';
import Feedback from './components/feedback/feedback';
import Accounts from './components/accounts/accounts';
import Welcome from './components/auth/welcome/welcome';
import Help from './components/help/help';

const PrivateRoute = ({ component: Component, ...rest }) => {

    if (!IdentitySerivce.getToken())
        return <Navigate to="/login" />;

    let isAdmin = IdentitySerivce.isAdmin();
    if (rest.onlyAdmin && !isAdmin)
        return <Navigate to="/" />;

    if (!rest.onlyAdmin && isAdmin)
        return <Navigate to="/notifications" />;

    return (
        <React.Fragment>
            <Header />
            <div id="main">
                <Sidebar />
                <div style={{
                    position: "relative",
                }}>
                    {
                        !rest.hideSectionTitle ?
                            <Card className="w-100 border-radius-0">
                                <CardBody className="py-3">
                                    <h5 className="m-0 text-bold text-muted">{i18next.t(rest.titleKey)}</h5>
                                </CardBody>
                            </Card> :
                            null
                    }
                    <div className={!rest.hideSectionTitle ? `main-content container-fluid` : null}>
                        <Component />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

const routes =
    <Routes>
        <Route path="/" exact element={<Navigate to="/portfolio" />} />
        <Route path="/holdings" exact element={<Navigate to="/portfolio" />} />

        <Route path="/dispatcher" element={<Dispatcher />} />

        <Route path="/login" element={<Login />} />
        <Route path="/reset" element={<ResetPassword />} />
        <Route path="/changePassword" element={<ChangePassword />} />
        <Route path="/welcome" element={<Welcome />} />

        <Route path="/notifications/create/:id" element={
           <PrivateRoute component={NotificationsCreate} titleKey={'NOTIFICATIONS_CREATE_NEW_VERSION'} onlyAdmin />
        } />
        <Route path="/notifications/create" element={
            <PrivateRoute component={NotificationsCreate} titleKey={'NOTIFICATIONS_CREATE'} onlyAdmin />
        } />
        <Route path="/notifications/edit/:id" element={
            <PrivateRoute component={NotificationsEdit} titleKey={'NOTIFICATIONS_EDIT'} onlyAdmin />
        } />
        <Route path="/notifications" element={
            <PrivateRoute  component={Notifications} titleKey={'NOTIFICATIONS'} onlyAdmin />
        } />
        <Route path="/security" element={
            <PrivateRoute component={Configurations} titleKey={'SECURITY'} onlyAdmin />
        } />
        <Route path="feedback" element={
            <PrivateRoute component={Feedback} titleKey={'FEEDBACK'} onlyAdmin />
        } />
        {/*<PrivateRoute path="/devices" component={Devices} titleKey={'MY_DEVICES'} />*/}
        <Route path="/accounts" element={
            <PrivateRoute component={Accounts} titleKey={'MY_ACCOUNTS'} />
        } />
        <Route path="/portfolio" element={
            <PrivateRoute component={Holdings} hideSectionTitle />
        } />
        <Route path="/portfolio/stock" element={
            <PrivateRoute component={Holdings} hideSectionTitle />
        } />
        <Route path="/portfolio/agent" element={
            <PrivateRoute component={Holdings} hideSectionTitle />
        } />
        <Route path="/historical" element={
            <PrivateRoute component={Historical} titleKey={'HISTORICAL'} />
        } />
        <Route path="/help" element={
            <PrivateRoute component={Help} titleKey={'HELP'} />
        } />

        <Route element={
            <PrivateRoute component={NoMatch} titleKey={'PAGE_NOT_FOUND'} />
        } />
    </Routes>;

export default routes;