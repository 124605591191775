

import React from 'react';

export default class Checkbox extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <span className={this.props.className || null}>
                <label className="checkbox">
                    <input id={this.props.id || 'check'}
                        type="checkbox"
                        checked={this.props.checked}
                        onChange={(e) => this.props.onChange(e.target.checked)} />
                    <span className="checkmark"></span>
                </label>
                <label className="ml-1 cursor-pointer mb-0" htmlFor={this.props.id || 'check'}>
                    {this.props.label}
                </label>
            </span>
        )
    }
}