import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import i18next from 'i18next';
import { If } from 'react-if';
import IdentitySerivce from '../../services/indentity.service';
import UtilsService from '../../services/utils/utils.service';
import Spinner from '../shared/spinner';

export default class AddAccountModal extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            email: '',
            password: '',
            showErrorMsg: false,
            isLoading: false
        }
    }

    cancel = () => {
        this.props.config.show = false;
    }

    onSubmit = (e) => {
        e.preventDefault();

        if (_isAccountAdded.bind(this)(this.state.email))
            return this.cancel();


        this.setState({
            isLoading: true
        });

        IdentitySerivce
            .login(this.state.email, this.state.password)
            .then((data) => {
                this.props.config.onSuccess && this.props.config.onSuccess(data);
                this.cancel();
            })
            .finally(() => this.setState({ isLoading: false }));
    }

    render() {
        return (
            <Modal isOpen={this.props.config.show} backdrop={true}>
                <ModalHeader>{i18next.t('ADD_ACCOUNT')}</ModalHeader>
                <ModalBody>
                    <form onSubmit={this.onSubmit}>
                        <div className="form-group">
                            <label>{i18next.t('EMAIL')}</label>
                            <input type="text" className="form-control" placeholder={i18next.t('EMAIL')}
                                value={this.state.email}
                                onChange={(e) => this.setState({email: e.target.value})}
                                required />
                        </div>
                        <div className="form-group">
                            <label>{i18next.t('PASSWORD')}</label>
                            <input type="password" className="form-control" placeholder={i18next.t('PASSWORD')}
                                value={this.state.password}
                                onChange={(e) => this.setState({password: e.target.value})}
                                required
                                autoComplete="new-password" />
                        </div>
                        <If condition={this.state.showErrorMsg}>
                            <div className="text-center">
                                <small className="text-danger">{i18next.t('USER_OR_PASSWORD_INVALID')}</small>
                            </div>
                        </If>
                        <div className="text-right">
                            <button type="button" className="btn btn-default btn-sm mr-2" onClick={this.cancel}>
                                {i18next.t('CANCEL')}
                            </button>
                            <button type="submit" className="btn btn-primary btn-sm" style={{ minWidth: '59px' }} onClick={this.ok}>
                                {i18next.t('ADD')}
                            </button>
                        </div>
                    </form>
                    {this.state.isLoading && <Spinner />}
                </ModalBody>
            </Modal>
        );
    }
}

function _isAccountAdded(email) {
    let accounts = JSON.parse(localStorage.getItem('acsa-multiaccount') || '[]');
    accounts.push(IdentitySerivce.getAuthData()); //Add current user

    for (let ma of accounts) {
        let item = UtilsService.parseJwt(ma.token);
        if (item.Email == email)
            return true;
    }
}