import React from 'react';
import i18next from 'i18next';
import { Card, CardBody } from 'reactstrap';
import UtilsService from './../../services/utils/utils.service';
import NoData from '../shared/noData';
import { If } from 'react-if';
import HoldingInstrumentTable from './holdingInstrument.table';

export default class HoldingInstrument extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() { }

    render() {
        let { data, filter, flags } = this.props;

        return (
            <div>
                {!!data.businessUnits ?
                    data.businessUnits.map((bu, index) =>
                        <Card key={index} className="border shadow mb-3">
                            <div className="p-1 border-bottom" style={{ borderTop: '2px solid #2977a7' }}>
                                <div className="text-bold lh-1">{bu.name || '-'}</div>
                                <span className="text-muted font-size-11 font-italic">
                                    <span>{ bu.identifier ? UtilsService.cuit(bu.identifier) : '' } </span>
                                </span>
                            </div>
                            <CardBody className="p-2">
                                {
                                    !!bu.assets ?
                                        <HoldingInstrumentTable
                                            items={bu.assets.instruments || []}
                                            totals={bu.assets.totals}
                                            isAsset={true}
                                            titleKey={'MY_INVESTMENTS'}
                                            icon='mis-inversiones.svg'
                                            index={index}
                                            subIndex={0} /> :
                                        null
                                }
                                {
                                    !!bu.futures ?
                                        <HoldingInstrumentTable
                                            items={bu.futures.instruments || []}
                                            totals={bu.futures.totals}
                                            isAsset={false}
                                            titleKey={'FUTURES'}
                                            icon='dif-fyo.svg'
                                            index={index}
                                            subIndex={1} /> :
                                        null
                                }
                                {
                                    !bu.assets && !bu.futures ?
                                        <NoData msg={i18next.t('NO_DATA')} /> :
                                        null
                                }
                            </CardBody>
                        </Card>
                    ) :
                    null
                }
                <If condition={!flags.isLoading && !flags.showBackOfficeError && !data.businessUnits || !data.businessUnits.length }>
                    <NoData msg={`${i18next.t('NO_DATA_WITH_DATE')}: ${UtilsService.dateFormat(filter.date)}`} />
                </If>
            </div>
        );
    }
}