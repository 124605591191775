import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import i18next from 'i18next';
import IdentitySerivce from '../../services/indentity.service';

const AGREE_KEY = 'acsa-terms-and-conditions-agree';
// const EMAIL_KEY = 'acsa-terms-and-conditions-email';
// const STORAGE_KEY = 'acsa-terms-and-conditions-warning-remember';

export default class TermsAndConditionsModal extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            user: IdentitySerivce.getUser() || {}
        };
    }

    close = () => {
        this.props.config.show = false;
    }

    continue = () => {
        this.props.config.onAgree && this.props.config.onAgree();
        this.close();
    }

    agree = () => {
        let check_conditions_agree = localStorage.getItem(AGREE_KEY);
        if(
            check_conditions_agree &&
            check_conditions_agree.search(this.state.user.Email) == -1
        ) {
            // add user to AGREE_KEY
            try{
                check_conditions_agree = JSON.parse(check_conditions_agree);
                check_conditions_agree.push(this.state.user.Email);
            }
            catch(e){
                // reset AGREE_KEY if its not in JSON format
                check_conditions_agree = [ this.state.user.Email ];
            }
            localStorage.setItem( AGREE_KEY, JSON.stringify(check_conditions_agree) );
        }

        this.props.config.onAgree && this.props.config.onAgree();
        this.close();
    }

    decline = () => {

        let check_conditions_agree = localStorage.getItem(AGREE_KEY);
            try{
                check_conditions_agree = JSON.parse(check_conditions_agree);
                for( let i = 0; i < check_conditions_agree.length; i++){
                    /* if( email == this.state.user.Email ){ 
                        check_conditions_agree.splice(i,1);
                        localStorage.setItem(AGREE_KEY, JSON.stringify(check_conditions_agree));
                        break;
                    } */
                    // TODO
                }
            }
            catch(e){}
        
        this.props.config.onDecline && this.props.config.onDecline();
        this.close();
    }

    isTermsAndConditionsAgree() {
        const AGREE_KEY = 'acsa-terms-and-conditions-agree';
        let check_conditions_agree = localStorage.getItem(AGREE_KEY);
        
        // console.log("agree:", check_conditions_agree);
        if( 
            check_conditions_agree &&
            check_conditions_agree.search( this.state.user.Email) != -1
        ) {
            // console.log("User agree!");
            return true;
        }
        return false;
    }

    render() {
        return (
            <Modal isOpen={this.props.config.show} backdrop={true}>
                <ModalHeader>{i18next.t('TERMS_AND_CONDITIONS')}</ModalHeader>
                <ModalBody>
                    <div>
                        <iframe src={i18next.t('TERMS_AND_CONDITIONS_FRAME_URL')} style={{
                            border: '0 none',
                            width: '100%',
                            height: '600px'
                        }}></iframe>

                        { !this.isTermsAndConditionsAgree() ? 
                            <div className="text-right">
                                <button type="button" className="btn btn-primary btn-sm mr-2" onClick={this.agree}
                                    style={{
                                        'backgroundColor': '#2977a7',
                                        'borderColor': '#2977a7'
                                    }}>
                                    {i18next.t('AGREE')}
                                </button>
                                <button type="button" className="btn btn-danger btn-sm" onClick={this.decline}
                                    style={{
                                        'backgroundColor': 'white',
                                        'borderColor': '#2977a7',
                                        'color': '#2977a7'
                                    }}>
                                    {i18next.t('DECLINE')}
                                </button>
                            </div> :
                            <div className="text-right">
                                <button type="button" className="btn btn-primary btn-sm mr-2" onClick={this.continue}
                                    style={{
                                        'backgroundColor': '#2977a7',
                                        'borderColor': '#2977a7'
                                    }}>
                                    {i18next.t('CONTINUE')}
                                </button>
                            </div>
                        }
                    </div>
                </ModalBody>
            </Modal>
        );
    }
}

