import React from 'react';
import i18next from 'i18next';
import { ButtonDropdown, DropdownToggle } from 'reactstrap';
import TermsAndConditionsModal from './termsAndConditions.modal';
import TermsAndConditionsWarningModal from './termsAndConditionsWarning.modal';

export default class TermsAndConditionsDropdown extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
            termsAndConditionsModalConfig: {
                show: false,
                onAgree: () => {
                    // Show warning modal
                    this.setState({
                        warningModalConfig: {
                            show: true
                        }
                    })
                }
            },
            warningModalConfig: {
                show: false
            }
        };
    }

    toggleDropdown = () => {
        let isOpen = !this.state.isOpen;

        if(isOpen) {
            // Open Terms and Conditions modal
            this.setState({
                termsAndConditionsModalConfig: {
                    ...this.state.termsAndConditionsModalConfig,
                    show: true
                },
                isOpen: isOpen
            })
        } else {
            this.setState({
                isOpen: isOpen
            });
        }
    }

    render() {
        return (
            <React.Fragment>
                <ButtonDropdown nav inNavbar isOpen={this.state.isOpen} toggle={this.toggleDropdown}>
                    <DropdownToggle nav title={i18next.t('TERMS_AND_CONDITIONS')}>
                        <img src={`/assets/img/ic_assignment.svg`} className="pt-1" height="24" width="24" />
                    </DropdownToggle> 
                </ButtonDropdown >

                {this.state.termsAndConditionsModalConfig.show && <TermsAndConditionsModal config={this.state.termsAndConditionsModalConfig} />}
                {this.state.warningModalConfig.show && <TermsAndConditionsWarningModal config={this.state.warningModalConfig} />}
            </React.Fragment>

        )
    }
}