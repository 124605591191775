import { Notyf } from 'notyf';

const notyf = new Notyf({
    duration: 10000 // 10s
});

const MessageSerivce = {
    success: (msg) => {
        notyf.success(msg);
    },
    error: (msg, options) => {
        let _options = {};
        if(options != null) { _options = {..._options, ...options} }
        _options['message'] = msg;

        notyf.error(_options);
    },
};

export default MessageSerivce;