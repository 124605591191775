import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import i18next from 'i18next';

export default class ConfirmaModal extends React.Component {
    constructor(props) {
        super(props);

        this.cancel = this.cancel.bind(this);
        this.ok = this.ok.bind(this);
    }

    cancel() {
        this.props.config.onCancel && this.props.config.onCancel();
        this.props.config.show = false;
    }

    ok() {
        this.props.config.onSuccess && this.props.config.onSuccess();
        this.props.config.show = false;
    }

    render() {
        return (
            <Modal isOpen={this.props.config.show} backdrop={true}>
                <ModalHeader>{i18next.t('CONFIRMATION')}</ModalHeader>
                <ModalBody>
                    <p>
                        {this.props.config.msg}
                    </p>
                    <div className="text-right">
                        <button type="button" className="btn btn-default btn-sm mr-2" onClick={this.cancel}>
                            {i18next.t('CANCEL')}
                        </button>
                        <button type="button" className="btn btn-primary btn-sm" style={{ minWidth: '59px' }} onClick={this.ok}>
                            {i18next.t('OK')}
                        </button>
                    </div>
                </ModalBody>
            </Modal>
        );
    }
}