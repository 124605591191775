import React from 'react';
import i18next from 'i18next';
import { Row, Col, Card, CardBody, CardHeader } from 'reactstrap';
import Spinner from '../shared/spinner';
import BackOfficeSerivce from './../../services/backOffice.service';
import NoData from '../shared/noData';

export default class Accounts extends React.Component {

    constructor(props) {
		super(props);

		this.state = {
			isLoading: true,
    		items: { 
				relatedClearingMembers:[], 
				clearingMembers: []
			}
		}
	}

    componentDidMount() {
        _getAccounts.bind(this)();
    }

    render() {
        return (
            <div className="devices position-relative">
				{
					( this.state.items.clearingMembers.length > 0 && !this.state.isLoading ) ?
					<Card className="mb-5">
						<CardHeader>
							<Row>
								<Col>
									<strong style={{ fontSize: "1.1rem" }}>
										{i18next.t('CLEARING_MEMBERS')}
									</strong>
								</Col>
							</Row>
						</CardHeader>
						<CardBody className="p-3">
							{this.state.items.clearingMembers.map((item, i) =>
								<Card className="shadow mb-3" key={`accounts-${i}`}>
									<CardHeader className="py-2 font-italic">
										<strong className="text-primary">{item.name}</strong>
									</CardHeader>
									<CardBody className="p-0">
										{(item.accounts || []).map((account, j) =>
											<div className={`card card-body border-radius-0 px-3 py-2`} key={`accounts-${i}-${j}`}>
												<div className="lh-1 mt-1">
													<strong>{account.accountName}</strong>
												</div>
												<div>
													<span className="mr-1">{i18next.t('ACCOUNT')}: </span>
													<span>{account.agentName}</span>
												</div>
											</div>
										)}
									</CardBody>
								</Card>
							)}
						</CardBody>
					</Card>
					:
					null
				}
				{
					( this.state.items.relatedClearingMembers.length > 0 && !this.state.isLoading ) ?
					<Card className="mt-5">
						<CardHeader>
							<Row>
								<Col>
									<strong style={{ fontSize: "1.1rem" }}>
										{i18next.t('RELATED_CLEARING_MEMBERS')}
									</strong>
								</Col>
							</Row>
						</CardHeader>
						<CardBody className="p-3">
								<Row className="align-items-center pb-3">
									<Col>
										{i18next.t('ACOUNT_MESSAGE')}
									</Col>
								</Row> 
							{this.state.items.relatedClearingMembers.map((item, i) =>
							<Card className="shadow mb-3" key={`accounts-${i}`}>
								<CardHeader className="py-2 font-italic">
									<strong className="text-primary">{item.name}</strong>
								</CardHeader>
								<CardBody className="p-0">
									{(item.accounts || []).map((account, j) =>
										<div className={`card card-body border-radius-0 px-3 py-2`} key={`accounts-${i}-${j}`}>
											<div className="lh-1 mt-1">
												<strong>{account.accountName}</strong>
											</div>

											<div>
												<span className="mr-1">{i18next.t('ACCOUNT')}: </span>
												<span>{account.agentName}</span>
											</div>

											<div>
												<span className="mr-1">{account.codedEmail}</span>
											</div>
										</div>
									)}
								</CardBody>
							</Card>
							)}
						</CardBody>
					</Card>
					:
					null
				}
				{
                    ( 	
						this.state.items.clearingMembers.length == 0 &&
						this.state.items.relatedClearingMembers.length > 0 &&
						!this.state.isLoading
					) ?
                        <NoData msg={i18next.t('NO_ACCOUNTS_FOUND')} /> :
                        null
                }

                {this.state.isLoading && <Spinner />}
            </div>
			
        );
    }
}

function _getAccounts() {
	this.setState({isLoading: true});

    BackOfficeSerivce
        .getClearingMembers()
        .then(items => this.setState({items: _mapFrom(items)}))
        .finally(() => this.setState({isLoading: false}))
}

function _mapFrom(items) {
	let return_items = { relatedClearingMembers:[], clearingMembers: [] };
	if( items ){
		if( items.relatedClearingMembers && items.relatedClearingMembers.length ){
			return_items.relatedClearingMembers = items.relatedClearingMembers.filter( item => (item.accounts && item.accounts.length))
		}
		if( items.clearingMembers && items.clearingMembers.length ){
			return_items.clearingMembers = items.clearingMembers.filter( item => (item.accounts && item.accounts.length))
		}
	}
    return return_items;
        
}