import React from 'react';
import Spinner from './../shared/spinner';
import i18next from 'i18next';
import IdentitySerivce from './../../services/indentity.service';
import UtilsService from './../../services/utils/utils.service';

class Dispatcher extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        let urlParams = new URLSearchParams(this.props.router.location.search);
        let params = {
            action: UtilsService.encodeURISecure(urlParams.get('action')),
            token: UtilsService.encodeURISecure(urlParams.get('token')),
            email: UtilsService.encodeURISecure(urlParams.get('email'))
            //action: urlParams.get('action'),
            //token: urlParams.get('token'),
            //email: urlParams.get('email')
        }

        switch (params.action) {
            case 'resetPassword':
                this.hanldeChangePassword(params);
                break;

            case 'loginCallback':
                this.hanldeLoginCallback(params);
                break;

            case 'loginWithToken':
                this.hanldeLoginWithToken();
                break;
    
            case 'login':
                this.hanldeLogin(params);
                break;

            case 'welcome':
                this.hanldeWelcome(params);
                break;
 
            default:
                break;
        }

        // Hide celphone banner
        if(document.getElementById('smartbanner')) {
            document.getElementById('smartbanner').style.display = 'none';
        }
    }

    hanldeChangePassword(params) {
        setTimeout(() => {
            this.props.router.navigate({
                pathname: '/changePassword',
                search: `?token=${params.token}&email=${params.email}`,
            });
        }, 1000);
    }

    hanldeLogin(params) {
        setTimeout(() => {
            this.props.router.navigate(`/login?email=${params.email}`);
        }, 1000);
    }
    
    hanldeWelcome(params) {
        setTimeout(() => {
            this.props.router.navigate(`/welcome?email=${params.email}`);
        }, 1000);
    }
    
    hanldeLoginCallback() {
        /*var urlWithoutHash = window.location.href.replace('#', '&');
    
        let urlParams = new URLSearchParams(urlWithoutHash);
        let token = UtilsService.decodeURISecure(urlParams.get('access_token'));
    
        IdentitySerivce
            .loginFromAuth0(token)
            .then((authData) => {
                IdentitySerivce.setAuthData(authData);
                //window.location.href = '/portfolio';
            })
            .catch(() => {
                //window.location.href = '/login'
            });*/
    }
    
    hanldeLoginWithToken() {
        var urlWithoutHash = window.location.href.replace('#', '&');
        
        let urlParams = new URLSearchParams(urlWithoutHash);
        let redirect_path = UtilsService.decodeURISecure(urlParams.get('redirect_path'));
        let token = UtilsService.decodeURISecure(urlParams.get('access_token'));
    
        try {
            // Set authdata
            IdentitySerivce.setAuthData({
                header: "Authorization",
                language: null,
                schema: "Bearer",
                token: token
            });
    
            // Go to redirect_path
            window.location.href = '/' + redirect_path;
        } catch(e) {
            window.location.href = '/login';
        }
    
    }

    render() {
        return (
            <div className="d-flex h-100" style={{ background: '#1A5588' }}>
                <div className="card m-auto shadow" style={{ minWidth: '300px' }}>
                    <div className="card-body">
                        <div className="row align-items-center">
                            <div className="col-3">
                                <Spinner />
                            </div>
                            <div className="col-9">
                                <h6 className="text-bold">
                                    {i18next.t('Usted esta siendo redirigido')}
                                </h6>
                                <h6 className="text-muted mb-0">
                                    {i18next.t('Por favor, espere...')}
                                </h6>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}

export default UtilsService.withRouter(Dispatcher);