import React from 'react';
import i18next from 'i18next';
import { toJS } from 'mobx';
import { Row, Col, Card, CardBody, CardHeader, Button, Collapse } from 'reactstrap';
import Spinner from '../shared/spinner';
import BackOfficeSerivce from '../../services/backOffice.service';
import UtilsService from '../../services/utils/utils.service';
import NoData from '../shared/noData';
import { Side } from './../../app.values';
import Currency from './../shared/currency';
import HistoricalStore from './historicalFilters.store';
import HistoricalPagination from './historicalPagination';
import TermsAndConditionsFooter from '../terms-and-conditions/footer';
import { Tooltip } from 'react-tooltip'
import axios from "axios";
import CoreService from '../../services/utils/core.service';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

const SideLabel = ({ side }) => {

    side = `${side}`;
    const data = Side[side];
    const chevronDir = data ? data.chevronDir : null;
    const label = data ? i18next.t(data.label) : side;

    return (
        <label className="side-label">
            <i className={`fa fa-chevron-${chevronDir} ${chevronDir == 'right' ? 'float-right pt-1' : ''}`}></i> {/* ` */}
            {label}
        </label>
    );
}

export default class Historical extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            showBackOfficeError: false,
            showExportError: false,
            showExportQueueMessage: false,

            openedIndexes: [],

            headers: [],
            periods: []
        }
    }

    componentDidMount() {
        // Get dataprovider
        return BackOfficeSerivce
            .getDateperiods()
            .then(periods => {
                this.setState({
                    periods: periods
                });
            })
            .catch((error) => {
                if (error.errorCodes && error.errorCodes.indexOf('BACK_OFFICE_ERROR') >= 0) {
                    this.setState({
                        showBackOfficeError: true
                    });
                }
            })
            .finally(() => {
                // Start
                this._realodData();
            });
    }

    toggleCollapse = (index) => {
        let headers = toJS(this.state.headers) || [];
        let header = headers[index] || { data: [] };

        if (!this.isCollapseOpen(index) && !header.data.length) {
            this._getAccountData(index);
        }

        // Toggle index
        let openedIndexes = this.state.openedIndexes;
        let k = openedIndexes.indexOf(index);
        if (k < 0) {
            openedIndexes.push(index);
        } else {
            openedIndexes.splice(k, 1);
        }

        this.setState({
            openedIndexes: openedIndexes
        });
    }

    isCollapseOpen(index) {
        return this.state.openedIndexes.indexOf(index) >= 0;
    }

    clearFilters = () => {
        HistoricalStore.clearFilters();
        this.setState({
            openedIndexes: []
        });

        this._realodData();
    }

    realod = () => {
        let index = HistoricalStore.openedIndexes.length ?
            HistoricalStore.openedIndexes[0] :
            null;

        this._realodData(index);
    }

    onPageChange = (pageNumber, index) => {
        let headers = toJS(this.state.headers) || [];
        headers[index].pageNumber = pageNumber;

        this.setState({
            headers: headers
        })
        this._getAccountData(index);
    }

    onDateChange = (d, isTo) => {
        // Clear opened indexes
        this.setState({
            openedIndexes: []
        });

        let date = (d && d.length) ? d[0] : '';
        let from, to;

        if (!isTo) {
            from = date;
            to = HistoricalStore.filter.to || '';
        } else {
            from = HistoricalStore.filter.from || '';
            to = date;
        }

        HistoricalStore.setFilter({
            from,
            to,
            agent: '',
            account: '',
            instrument: '',
        });

        this._realodData();
    }

    onPeriodChange = (ref) => {
        // Clear opened indexes
        this.setState({
            openedIndexes: []
        });

        const period = this.state.periods.find(_ => _.ref == ref);

        // Period
        let from = period.startDate;
        let to = period.endDate;

        HistoricalStore.setFilter({
            from,
            to,
            agent: '',
            account: '',
            instrument: '',
            period: ref,
        });

        this._realodData();
    }

    /**
     * Export to Excel
     */
    exportToExcel = () => {
        let params = UtilsService.deepCopy(HistoricalStore.filter);
        let url = `${window.apiUrl}/api/BackOffice/history-export`;
        url = UtilsService.addParamsToUrl(url, params);

        // Axios config
        let config = CoreService.getAxiosConfig();

        // Show loading
        this.setState({
            isLoading: true,
            showExportError: false
        });

        // Axios request
        axios
            .get(encodeURI(url), config)

            .then(response => {
                var r = response.data;

                if(r.httpCode == 200 && r.data) {
                    // Check response type
                    if(r.data.type == 'real-time') {
                        // save file
                        //window.location.href = r.data.file.url;
                    } else if(r.data.type == 'queue') {
                        // Show message
                        this.setState({
                            showExportQueueMessage: true
                        });
                    } else {
                        // Error
                        this.setState({
                            showExportError: true
                        });
                    }
                } else {
                    // Error
                    this.setState({
                        showExportError: true
                    });
                }
            })

            .catch((error) => {
                this.setState({
                    showExportError: true
                });
            })

            .finally(() => {
                this.setState({
                    isLoading: false
                });
            })
        ;
    }

    hideExportQueueMessage = () => {
        this.setState({
            showExportQueueMessage: false
        });
    }

    render() {
        return (
            <React.Fragment>
                <div className="historical pb-3">
                    <Card>
                        <CardBody>
                            {
                                HistoricalStore.isSomeFilter ?
                                    <div className="clear-filters">
                                        <Button
                                            type="button"
                                            color="link"
                                            onClick={this.clearFilters}>
                                            {i18next.t('CLEAR_FILTERS')}
                                        </Button>
                                    </div> :
                                    null
                            }
                            <Row className="mb-4 align-items-end">
                                { /* <Col md="3">
                                    <label className="mb-1 w-100">
                                        <i className="fa fa-calendar mr-1"></i>
                                        {i18next.t('FROM')}:
                                    </label>
                                    <Flatpickr
                                        className="form-control bg-white"
                                        options={{
                                            dateFormat: "d/m/Y",
                                            maxDate: HistoricalStore.filter.to ? UtilsService.dateFormat(HistoricalStore.filter.to) : 'today',
                                            locale: UtilsService.getFlatpickrLocale(),
                                            allowInput: true
                                        }}
                                        value={HistoricalStore.filter.from}
                                        onChange={(date) => this.onDateChange(date)}
                                        readOnly />
                                </Col>
                                <Col md="3" className="position-relative">
                                    <label className="mb-1">
                                        <i className="fa fa-calendar mr-1"></i>
                                        {i18next.t('TO')}:
                                    </label>
                                    <Flatpickr
                                        className="form-control bg-white"
                                        value={HistoricalStore.filter.to}
                                        options={{
                                            dateFormat: "d/m/Y",
                                            minDate: HistoricalStore.filter.from ? UtilsService.dateFormat(HistoricalStore.filter.from) : null,
                                            maxDate: "today",
                                            locale: UtilsService.getFlatpickrLocale(),
                                            allowInput: true
                                        }}
                                        onChange={(date) => this.onDateChange(date, true)}
                                        readOnly />
                                </Col> */ }
                                <Col md="3">
                                    <label>{i18next.t('PERIOD')}:</label>
                                    <select className="form-control"
                                        value={HistoricalStore.filter.period}
                                        onChange={(e) => { 
                                            this.onPeriodChange(e.target.value)
                                        }}
                                    >
                                        { this.state.periods.map((item, index) =>
                                            <option key={index} value={item.ref}>{item.label}</option>
                                        ) }
                                    </select>
                                </Col>
                                <Col className="text-right">
                                    <Button
                                        type="button"
                                        outline
                                        color="primary"
                                        size="sm"
                                        onClick={this.realod}>
                                        {i18next.t('REALOAD')}
                                    </Button>
                                </Col>
                            </Row>

                            { <div className="mb-4 text-right">
                                <button type="button" className="btn btn-primary" onClick={this.exportToExcel}>
                                    <i className="fa fa-file-pdf-o mr-2"></i>
                                    {i18next.t('DOWNLOAD_XLSX')}
                                </button> 

                                { this.state.showExportError ? 
                                    <p className="pt-2">
                                        {i18next.t('DOWNLOAD_XLSX_ERROR')}
                                    </p> 
                                    : null }
                            </div> }

                            {this.state.headers.map((h, index) =>
                                <Card className="ml-2 mb-3 position-relative" key={index}>
                                    <CardHeader className="cursor-pointer py-1 d-flex" onClick={() => this.toggleCollapse(index)}>
                                        <div className="flex-grow-1 align-self-center">
                                            {/* <div className="lh-1 text-muted font-size-11 font-italic">
                                                <strong>{h.accountName}</strong>
                                            </div> */}
                                            <div className="lh-1 text-muted font-size-11 font-italic">
                                                <span className="mr-1">{i18next.t('ACCOUNT')}: </span>
                                                <strong>{h.accountCode} - {h.accountName}</strong>
                                            </div>
                                            <div className="lh-1 text-muted font-size-11 font-italic">
                                                <span className="mr-1">{i18next.t('AGENT')}: </span>
                                                <strong>{h.clearingMember}</strong>
                                            </div>
                                            <div className="lh-1 text-muted font-size-11 font-italic">
                                                <span className="mr-1">{i18next.t('TOTAL_RECORDS')}: </span>
                                                <strong>{h.registersNumber}</strong>
                                            </div>
                                        </div>
                                        <Button className="m-0" color="link" size="sm">
                                            <i className={`fa fa-chevron-${this.isCollapseOpen(index) ? 'up' : 'down'}`}></i> {/* ` */}
                                        </Button>
                                    </CardHeader>
                                    <Collapse isOpen={this.isCollapseOpen(index)}>
                                        <CardBody className="p-2">
                                            <div className="table-responsive">
                                                <table className="table table-sm mb-0">
                                                    <thead>
                                                        <tr className="text-muted">
                                                            <th style={{ width: '1px' }}></th>
                                                            <th style={{ width: '150px' }}>{i18next.t('DATE')}</th>
                                                            <th style={{ width: '40%' }}>{i18next.t('INSTRUMENT')}</th>
                                                            <th>{i18next.t('PURPOSE')}</th>
                                                            <th className="text-right">{i18next.t('QUANTITY')}</th>
                                                            <th className="text-right">
                                                                <span 
                                                                    data-tooltip-id="price-tooltip"
                                                                    data-tooltip-content={i18next.t('PRICE_TOOLTIP')}
                                                                >
                                                                    {i18next.t('PRICE')}
                                                                </span>
                                                            </th>
                                                            <th className="text-right">
                                                                <span 
                                                                    data-tooltip-id="valued-active-tooltip"
                                                                    data-tooltip-content={i18next.t('VALUED_ACTIVE_TOOLTIP')}
                                                                >
                                                                    {i18next.t('VALUE')}
                                                                </span>
                                                            </th>
                                                            <th className="text-right">{i18next.t('CURRENCY_SYMBOL')}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {h.data.map((d, i) =>
                                                            <tr key={i}>
                                                                <td className="pr-2" style={{ 'whiteSpace': 'nowrap'}}>
                                                                    <SideLabel side={d.side} />
                                                                </td>
                                                                <td>{UtilsService.dateFormat(d.date, 'dd/MM/yyyy hh:mm:ss')}</td>
                                                                <td>{d.instrument || '-'}</td>
                                                                <td>{d.purpose || '-'}</td>
                                                                <td className="text-right">
                                                                    <Currency amount={d.quantity} symbol={false} />
                                                                </td>
                                                                <td className="text-right">
                                                                    <Currency amount={d.price} symbol={false} />
                                                                </td>
                                                                <td className="text-right">
                                                                    {
                                                                        d.type == 'Asset' && d.amount != null ?
                                                                            <Currency
                                                                                amount={d.amount}
                                                                                symbol={false} />
                                                                            : '-'
                                                                    }
                                                                </td>
                                                                <td className="text-right">
                                                                    {UtilsService.translateCurrencySymbol(d.currencySymbol)}
                                                                </td>
                                                            </tr>
                                                        )}
                                                        {!h.data.length && !h.isLoading ?
                                                            <tr>
                                                                <td colSpan="99">
                                                                    <NoData className="pt-2" msg={i18next.t('NO_RESULTS')} />
                                                                </td>
                                                            </tr> :
                                                            null
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                            
                                            <HistoricalPagination
                                                page={h.pageNumber}
                                                total={h.registersNumber}
                                                onChange={(pageNumber) => this.onPageChange(pageNumber, index)} />

                                        </CardBody>
                                    </Collapse>
                                    {h.isLoading && <Spinner />}

                                    <Tooltip id="price-tooltip" />
                                    <Tooltip id="valued-active-tooltip" />
                                </Card>
                            )}

                            {!!this.state.showBackOfficeError ?
                                <NoData imgSrc="/assets/img/no-internet.svg" msg={`${i18next.t('CANT_SHOW_DATA_ATM')}`} /> :
                                (!this.state.isLoading && !this.state.headers.length) ?
                                    <NoData msg={`${i18next.t('NO_DATA_WITH_DATE_RANGE')}: ${UtilsService.dateFormat(HistoricalStore.filter.from)} - ${UtilsService.dateFormat(HistoricalStore.filter.to)}`} /> :
                                    null
                            }
                        </CardBody>

                        {this.state.isLoading && <Spinner />}
                    </Card>

                    {(this.state.headers && this.state.headers.length) ? <TermsAndConditionsFooter /> : null}


                </div>

                { this.state.showExportQueueMessage ? 
                    <Modal isOpen={true} backdrop={true}>
                        <ModalHeader>{i18next.t('EXPORT_QUEUE')}</ModalHeader>
                        <ModalBody>
                            <div>
                                <p>{i18next.t('EXPORT_QUEUE_MESSAGE')}</p>

                                <div className="text-right d-flex align-items-center justify-content-end mr-2">
                                    <button type="button" 
                                        className="btn btn-primary btn-sm mr-2"
                                        onClick={this.hideExportQueueMessage}
                                        style={{
                                            'backgroundColor': '#2977a7',
                                            'borderColor': '#2977a7',
                                        }}>
                                        {i18next.t('OK')}
                                    </button>
                                </div> 
                            </div>
                        </ModalBody>
                    </Modal>
                    : null
                }
           
            </React.Fragment>
        );
    }


    // Private
    _realodData(index) {
        this._getItems()
            .then(() => {
                if (index >= 0) {
                    this._getAccountData(index);
                } else {
                    this.toggleCollapse(0);
                }
            });
    }

    _getItems() {
        this.setState({
            isLoading: true,
            showBackOfficeError: false
        });

        let filter = this._getFilter();

        return new Promise(resolve => {
            BackOfficeSerivce
                .getHeaders(filter)
                .then(headers => {
                    const _headers = this._mapHeadersFrom(headers);

                    this.setState({
                        headers: _headers,
                        isLoading: false
                    }, resolve);
                })
                .catch((error) => {
                    this.setState({
                        showBackOfficeError: true,
                        isLoading: false
                    }, resolve);
                })

        });
    }

    _getAccountData(index) {
        let headers = toJS(this.state.headers) || [];

        if (headers.length) {
            let filter = this._getFilter();

            filter.account = headers[index].accountCode;
            filter.pageNumber = headers[index].pageNumber;
            headers[index].isLoading = true;

            this.setState({
                headers: headers
            })

            BackOfficeSerivce
                .getHistory(filter)
                .then(data => {
                    headers[index].data = data;
                    headers[index].isLoading = false;
                    
                    this.setState({
                        headers: headers
                    });
                });
        }
    }

    _getFilter() {
        let filter = UtilsService.deepCopy(HistoricalStore.filter);
        return filter;
    }

    _mapHeadersFrom(data = []) {
        return data.map(d => ({
            ...d,
            pageNumber: 1,
            isLoading: false,
            data: []
        }));
    }
}