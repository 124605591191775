import React from 'react';
import i18next from 'i18next';
import { Row, Col } from 'reactstrap';


const PAGE_SIZE = 25;

export default class HistoricalPagination extends React.Component {

    getOptions = (pageCount) => {
        let jsx = [];
        for (let i = 1; i <= pageCount; i++)
            jsx.push(<option key={`option${i}`} value={i}>{i}</option>);

        return jsx;
    }

    render() {
        let { page = 0, total = 0 } = this.props;

        let pageCount = Math.ceil(total / PAGE_SIZE);
        let hasPrev = total > PAGE_SIZE && page > 1;
        let hasNext = pageCount > page;

        if (
            (total <= 0) ||
            (page < 1) ||
            (!hasPrev && !hasNext)
        ) {
            return null;
        }

        return (
            <Row className="align-items-center py-2">
                <Col sm="6">
                    <span>
                        {i18next.t('PAGE')}
                    </span>

                    <select className="mx-2" value={page} onChange={(e) => this.props.onChange(+e.target.value)}>
                        {this.getOptions(pageCount)}
                    </select>

                    <span>{i18next.t('OF')} {pageCount}</span>
                </Col>
                <Col sm="6" className="text-right">
                    <button type="button" className={`btn btn-link p-0 ${!hasPrev ? 'disabled' : ''}`}
                        onClick={() => hasPrev && this.props.onChange(page - 1)}>
                        {i18next.t('PREVIOUS')}
                    </button>
                    <span>{' | '}</span>
                    <button type="button" className={`btn btn-link p-0 ${!hasNext ? 'disabled' : ''}`}
                        onClick={() => hasNext && this.props.onChange(page + 1)}>
                        {i18next.t('NEXT')}
                    </button>
                </Col>
            </Row>

        )
    }
}