import CoreService from './utils/core.service';

const route = 'Notifications';

const NotificationsService = {
    getAdmin: (onlyLastVersions) => CoreService.get(`${route}/admin`, { onlyLastVersions }),
    get: (id) => CoreService.get(`${route}/${id}`),
    create: (item) => CoreService.post(`${route}`, item),
    edit: (id, item) => CoreService.put(`${route}/${id}`, item),
    newVersion: (id, item) => CoreService.put(`${route}/${id}/version`, item),
    delete: (id) => CoreService.delete(`${route}/${id}`),
};

export default NotificationsService;