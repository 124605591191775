import React from 'react';
import { Navigate } from 'react-router-dom';
import axios from "axios";
import UtilsService from "./utils.service";
import MessageSerivce from './message.service';
import IdentitySerivce from "../indentity.service";
import i18next from 'i18next';

let $http = axios.create({
    baseURL: `${window.apiUrl}/api/`
});

let CoreService = {
    setHeader(key, value) {
        $http.defaults.headers.common[key] = value;
    },
    get(url, data, customCacth) {
        url = UtilsService.addParamsToUrl(url, data);
        return callAsync($http.get(encodeURI(url), CoreService.getAxiosConfig()), customCacth);
    },
    delete(url, data, customCacth) {
        url = UtilsService.addParamsToUrl(url, data);
        return callAsync($http.delete(url, CoreService.getAxiosConfig()), customCacth);
    },
    put(url, data, customCacth) {
        return callAsync($http.put(url, data, CoreService.getAxiosConfig()), customCacth);
    },
    post(url, data, customCacth) {
        return callAsync($http.post(url, data, CoreService.getAxiosConfig()), customCacth);
    },
    uploadFile(url, file) {
        let formData = new FormData();
        formData.append('files', file);

        return callAsync($http.post(url, formData, CoreService.getAxiosConfig()));
    },

    /**
     * Get Axios config
     */
    getAxiosConfig() {
        let headers = {
            'Content-Type': 'application/json'
        };
    
        let authData = JSON.parse(UtilsService.getCookie('acsa_auth_data'));
        if (authData) {
            let header = authData.header || 'Authorization';
            let token = authData ? UtilsService.getTokenBySchema(authData.schema, authData.token) : '';
            headers[header] = token;
        }
    
        return { headers };
    },
    
};

function callAsync(httpPromise, customCacth) {
    return new Promise((resolve, reject) =>
        httpPromise
            .then((http) => respondeHalder(http, resolve, reject, customCacth))
            .catch((http) => respondeHalder(http ? http.response : {}, resolve, reject, customCacth))
    );
}

function respondeHalder(response, resolve, reject, customCacth) {
    if (!response)
        return reject(response);

    if (response.status >= 200 && response.status < 300) //SUCCESS
        return response.data ? resolve(response.data.data) : resolve(response.data);

    //Error
    errorHanlder(response, resolve, reject, customCacth);
}

function errorHanlder(response, resolve, reject, customCacth) {
    let res = response.data;
    switch (response.status) {
        case 400:
            if (customCacth) {
                return reject(res);
            }

            if(res.errorCodes && res.errorCodes.includes('INVALID_CREDENTIALS')) {
                MessageSerivce.error(i18next.t('USER_OR_PASSWORD_INVALID'));
            } else {
                res.errorMessages && res.errorMessages.forEach(msg => MessageSerivce.error(msg));
            }

            return reject(res);
        case 401:
            IdentitySerivce.removeCookies();
            window.location.href = '/login';
            return reject();

        default:
            MessageSerivce.error(i18next.t('ERROR_MSG'));
            return reject();
    }

}
export default CoreService;