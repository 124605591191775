import React from 'react';
import { Link } from 'react-router-dom';
import i18next from 'i18next';
import IdentitySerivce from './../../../services/indentity.service';
import Spinner from './../../shared/spinner';
import AuthChangeLangBtn from './../authChangeLangBtn';
import UtilsService from './../../../services/utils/utils.service';
import Switch from "react-switch";
import TermsAndConditionsModal from '../../terms-and-conditions/termsAndConditions.modal';
import TermsAndConditionsWarningModal from '../../terms-and-conditions/termsAndConditionsWarning.modal';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { faEyeSlash } from '@fortawesome/free-solid-svg-icons';
const eye = <FontAwesomeIcon icon={faEye} size="lg" color="#888888" />;
const eyeSlash = <FontAwesomeIcon icon={faEyeSlash} size="lg" color="#888888"/>;

const REMEMBER_LOGIN_STORAGE_KEY = 'acsa-remember-login';

class Login extends React.Component {

    /* webAuth = new auth0.WebAuth({
        domain: window.authDomain || '',
        clientID: window.authClientID || '',
        responseType: 'token',
        scope: 'openid email profile',
        redirectUri: `${UtilsService.getBaseUrl()}/dispatcher?action=loginCallback`
    }); */

    constructor(props) {
        super(props);

        this.state = {
            hidden: true,

            email: '',
            password: '',
            showErrorMsg: false,
            showEmailSent: false,
            isLoading: false,

            rememberSession: false,

            termsAndConditionsModalConfig: {
                show: false
            },
            termsAndConditionsWarningModalConfig: {
                show: false
            },
        }
        this.togglePassword = this.togglePassword.bind(this);
    }

    componentDidMount() {

        // Params
        const urlParams = new URLSearchParams(this.props.router.location.search);
        const urlParamEmail = UtilsService.decodeURISecure(urlParams.get('email'));
        this.setState({
            email: urlParamEmail
        });

        // Remember session & Auto login
        if(urlParamEmail == '') {
            const _rememberSessionData = this.getRememberLoginData();
            if('remember' in _rememberSessionData) {
                // Remember session switch
                this.setState({
                    rememberSession: _rememberSessionData.remember ? true : false
                });

                // Email
                if('lastEmail' in _rememberSessionData && _rememberSessionData.lastEmail != '') {
                    this.setState({
                        email: _rememberSessionData.lastEmail
                    });
                }

                // Password
                if(_rememberSessionData.remember) {
                    // Auto complete data
                    if('password' in _rememberSessionData &&  _rememberSessionData.password != '' && this.state.email != '') {
                        this.setState({
                            password: _rememberSessionData.password
                        });

                        // Auto Login
                        this.onSubmit(null);
                    }
                }
            }
        }
    }

    changeLanguage = () => {
        i18next
            .changeLanguage(i18next.language == 'es' ? 'en' : 'es', () => this.forceUpdate());
    }

    onSubmit = (e) => {
        if(e) e.preventDefault();

        this.setState({
            showErrorMsg: false,
            isLoading: true
        })

        IdentitySerivce
            .login(this.state.email, this.state.password)
            .then((data) => { // SUCCESS
                IdentitySerivce.setAuthData(data);
                this.props.router.navigate('/portfolio');

                // Remember sesion
                let _rememberSessionData = {
                    remember: this.state.rememberSession,
                    lastEmail: this.state.email
                }
                if(this.state.rememberSession) {
                    _rememberSessionData.password = this.state.password;
                }
                this.setRememberLoginData(_rememberSessionData);

                // Continue
                this.loginSuccess(data);
            }, () => { // ERROR
                this.setState({showErrorMsg: true});
            })
            .finally(() => this.setState({isLoading: false}))
    }

    /**
     * On change: Remember seesion
     */
    rememberSessionOnChange = (checked) => {
        this.setState({
            rememberSession: checked
        });
    }

    /**
     * Is terms and conditions agree ?
     */
     isTermsAndConditionsAgree() {
        const AGREE_KEY = 'acsa-terms-and-conditions-agree';
        let check_conditions_agree = localStorage.getItem(AGREE_KEY);
        
        // console.log("agree:", check_conditions_agree);
        if(
            check_conditions_agree &&
            check_conditions_agree.search( this.state.email) != -1
        ) {
            // console.log("User agree!");
            return true;
        }
        return false;
    }

    isTermsAndConditionsWarningToRemember() {
        // return true; // ONLY for testing
        const STORAGE_KEY = 'acsa-terms-and-conditions-warning-remember';
        let conditions_warning_remember = localStorage.getItem(STORAGE_KEY);
        if( 
            conditions_warning_remember &&
            conditions_warning_remember.search(this.state.email) != -1
        ) {
            // console.log("User dont want warning to remember");
            return false;
        }
        // console.log("User not found. Remember!");
        return true;
    }

    loginSuccess(data) {
        // Show Terms And Conditions
        if(!this.isTermsAndConditionsAgree()) {
            // Show terms and conditions
            this.setState({
                termsAndConditionsModalConfig: {
                    show: true,
                    onAgree: () => {
                        // Show warning modal
                        this.setState({
                            termsAndConditionsWarningModalConfig: {
                                show: true,
                                onToRememberAgain: () => {
                                    // Continue normal > Redirect
                                    this.props.router.navigate('/portfolio');
                                },
                                onDoNotRememberAgain: () => {
                                    // Continue normal > Redirect
                                    this.props.router.navigate('/portfolio');
                                }
                            }
                        });
                    },
                    onDecline: () => {
                        // :(
                    }
                }
            })

            return;
        }

        // Show Terms And Conditions Warning
        if(this.isTermsAndConditionsWarningToRemember()) {
            // Show warning
            this.setState({
                termsAndConditionsWarningModalConfig: true,
                onToRememberAgain: () => {
                    // Continue normal > Redirect
                    this.props.router.navigate('/portfolio');
                },
                onDoNotRememberAgain: () => {
                    // Continue normal > Redirect
                    this.props.router.navigate('/portfolio');
                }
            })

            return;
        }

        // Continue normal > Redirect
        this.props.router.navigate('/portfolio');
    }

    onSocialLogin = () => {
        this.setState({isLoading: false});
        //this.webAuth.authorize();
    }

    /** 
     * Get remember login data
     */
    getRememberLoginData() {
        try {
            const _data = localStorage.getItem(REMEMBER_LOGIN_STORAGE_KEY);
            if(_data) {
                return JSON.parse(atob(_data));
            }
        } catch (e) {

        }

        return {};
    }

    /** 
     * Set remember login data
     */
    setRememberLoginData(data) {
        localStorage.setItem(REMEMBER_LOGIN_STORAGE_KEY, btoa(JSON.stringify(data)));
    }

    togglePassword = () => {
        this.setState({ hidden: !this.state.hidden })
    }

    render() {
        return (
            <div className="auth">
                <div className="auth-container">
                    <div className="card border-bottom-0">
                        <div className="card-header d-flex py-4">
                            <img className="m-auto"
                                src="/assets/img/miportafolio.svg"
                                height="52" />
                        </div>
                        <div className="card-body">
                            <form onSubmit={this.onSubmit}>
                                <h5 className="text-muted text-center mb-3 mt-1">{i18next.t('LOGIN')}</h5>
                                <div className="form-group">
                                    <input type="text" className="form-control" placeholder={i18next.t('EMAIL')}
                                        value={this.state.email}
                                        onChange={(e) => this.setState({email: e.target.value})}
                                        required />
                                </div>
                                <div className="form-group">
                                    <input type={this.state.hidden ? "password" : "text"} 
                                        className="form-control"
                                        placeholder={i18next.t('PASSWORD')}
                                        value={this.state.password}
                                        onChange={(e) => this.setState({password: e.target.value})}
                                        autoCorrect="off"
                                        required />
                                    <button type="button"
                                        onClick={this.togglePassword}
                                        className="password-button">
                                        {this.state.hidden ? eyeSlash : eye }
                                    </button>
                                </div>
                                <button type="submit" className="btn btn-info btn-lg w-100">
                                    {i18next.t('LOGIN')}
                                </button>
                                <div className="form-group mt-3">
                                    <label className="d-flex align-items-center justify-content-center">
                                        <Switch onChange={this.rememberSessionOnChange} checked={this.state.rememberSession} uncheckedIcon={false} checkedIcon={false} onColor="#17a2b8" />
                                        <span className="text-muted ml-2">{i18next.t('REMEMBER_USER')}</span>
                                    </label>
                                </div>
                                { this.state.showErrorMsg ?
                                    <div className="text-center">
                                        <small className="text-danger">{i18next.t('USER_OR_PASSWORD_INVALID')}</small>
                                    </div>
                                    : null
                                }
                                <div className="mt-4 text-center">
                                    <Link to="/reset" className="btn btn-link m-0 d-block">
                                        {i18next.t('RESET_PASSWORD')}
                                    </Link>
                                    <a href="https://www.miportafolio.com.ar/new-user" target="_blank" className="btn btn-link m-0 d-block">
                                        {i18next.t('REGISTER_FIRST_ACCESS')}
                                    </a>
                                    <AuthChangeLangBtn onChange={() => this.forceUpdate()} />
                                </div>
                            </form>
                        </div>

                        {/*<button type="button" className="btn btn-light d-block" onClick={this.onSocialLogin}>
                            <i className="fa fa-gear mr-2 text-muted"></i>
                            <a href="#">{i18next.t('ARE_YOU_ADMIN')}</a>
                        </button>*/}

                        <div className="card-footer"
                            style={{ backgroundColor: '#b1b1b1' }}>
                            <div className="text-center mb-2">
                                <img height="48" src="/assets/img/logo.png" />
                            </div>
                        </div>
                    </div>
                    {this.state.isLoading && <Spinner />}
                </div>

                {this.state.termsAndConditionsModalConfig.show && <TermsAndConditionsModal config={this.state.termsAndConditionsModalConfig} />}
                {this.state.termsAndConditionsWarningModalConfig.show && <TermsAndConditionsWarningModal config={this.state.termsAndConditionsWarningModalConfig} />}
            </div>
        );
    }
}

export default UtilsService.withRouter(Login);