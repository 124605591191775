import React from 'react';

export default class NoData extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className={`w-100 text-center noData ${this.props.className || ''}`}>
                <img src={this.props.imgSrc || '/assets/img/no-results.svg'} height="250" />
                <div className="mt-3" >
                    <strong>{this.props.msg}</strong>
                </div>
            </div>
        )
    }
}