import React from 'react';
import i18next from 'i18next';
import WelcomeSuccessful from './welcomeSuccessful';
import { If } from 'react-if';
import Spinner from '../../shared/spinner';
import AuthChangeLangBtn from './../authChangeLangBtn';
import UtilsService from '../../../services/utils/utils.service';
import UserSerivce from '../../../services/user.service';

class Welcome extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            emailSent: false,
            email: '',
            password: ''
        };
    }

    componentDidMount() {
        const urlParams = new URLSearchParams(this.props.router.location.search);
        this.setState({
            email: UtilsService.decodeURISecure(urlParams.get('email'))
        });
    }

    onSubmit = (e) => {
        e.preventDefault();
        
        this.setState({
            isLoading: true
        });

        UserSerivce
            .registerFirstLogin(this.state.email, this.state.password)
            .then((data) => { //SUCCESS
                //IdentitySerivce.setAuthData(data);
                //this.props.history.push('/portfolio');

                this.setState({isLoading: true})
            }, (error) => { //ERROR
                //this.state.showErrorMsg = true;
                console.log(error);
            })
            .finally(() => this.setState({isLoading: false}));
    }

    render() {
        return (
            <div className="auth">
                <div className="auth-container">
                    <div className="text-center mb-2">
                        <img height="48" src="/assets/img/logo.png" />
                    </div>
                    <div className="card">
                        <div className="card-body">
                            <If condition={!this.state.emailSent}>
                                <form onSubmit={this.onSubmit} autoComplete="off">
                                    <h3 className="text-muted text-center">{i18next.t('WELCOME_TITLE')}</h3>
                                    <p className="text-muted text-center mb-4 mt-1">{i18next.t('WELCOME_INFO_TEXT')}</p>
                                    <div className="form-group">
                                        <input type="email" className="form-control"
                                            placeholder={i18next.t('EMAIL')}
                                            value={this.state.email}
                                            onChange={(e) => this.setState({email: e.target.value})}
                                            required />
                                    </div>
                                    <div className="form-group">
                                        <input type="password" className="form-control"
                                            placeholder={i18next.t('PASSWORD')}
                                            value={this.state.password}
                                            onChange={(e) => this.setState({password: e.target.value})}
                                            required />
                                    </div>
                                    <button type="submit" className="btn btn-info btn-lg w-100">
                                        {i18next.t('WELCOME_SUBMIT')}
                                    </button>
                                </form>
                            </If>
                            <If condition={this.state.emailSent}>
                                <WelcomeSuccessful onResendClick={() => this.setState({emailSent: false})} />
                            </If>

                            <div className="text-center mt-3">
                                <AuthChangeLangBtn onChange={() => this.forceUpdate()} />
                            </div>
                        </div>
                    </div>
                    {this.state.isLoading && <Spinner />}
                </div>
            </div>
        );
    }
};

export default UtilsService.withRouter(Welcome);