import CoreService from './utils/core.service';
import UtilsService from './utils/utils.service';

const route = 'Identity';
const cookieKey = 'acsa_auth_data';
var cachedUser = null;

const IdentitySerivce = {
    //HTTP
    get: () => CoreService.get(`${route}`),
    login: (username, password) => CoreService.post(`${route}/login`, { username, password }),
    logout: () => CoreService.post(`${route}/logout`),
    resetPassword: (email) => CoreService.post(`Users/password/recover`, JSON.stringify(email)),
    changePassword: (item) => CoreService.post(`Users/password/reset`, item, true),
    loginFromAuth0: (token) => CoreService.get(`${route}/auth?access_token=${token}`),

    //Custom
    getAuthData() {
        let authDate = UtilsService.getCookie(cookieKey);
        return authDate ? JSON.parse(authDate) : null;
    },
    setAuthData(authData) {
        authData && UtilsService.setCookie(cookieKey, JSON.stringify(authData));
    },
    getToken() {
        let authData = JSON.parse(UtilsService.getCookie(cookieKey));
        return authData ? UtilsService.getTokenBySchema(authData.schema, authData.token) : null;
    },
    getUser() {
        if (cachedUser)
            return cachedUser;

        let authData = JSON.parse(UtilsService.getCookie(cookieKey));
        cachedUser = authData ? UtilsService.parseJwt(authData.token) : null;
        return cachedUser;
    },
    isAdmin() {
        let user = IdentitySerivce.getUser() || {};
        return user.Roles ? user.Roles.includes('Admin') : false;
    },
    removeCookies() {
        UtilsService.deleteCookie(cookieKey);
    }
};

export default IdentitySerivce;