import React from 'react';
import i18next from 'i18next';
import { UncontrolledDropdown, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Row, Col, Button } from 'reactstrap';
import IdentitySerivce from './../../services/indentity.service';
import UserAvatar from '../shared/userAvatar';
import AddAccountModal from './addAccount.modal';
import UtilsService from '../../services/utils/utils.service';
import { Link } from 'react-router-dom';
import { toJS } from 'mobx';

const STORAGE_KEY = 'acsa-multiaccount';
const REMEMBER_LOGIN_STORAGE_KEY = 'acsa-remember-login';

export default class UserDropdown extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
            user: IdentitySerivce.getUser() || {},
            multiaccounts: _getMultiaccounts.bind(this)(),
            accountModalConfig: {
                show: false
            }
        };

    }

    addAccount = () => {
        this.setState({
            accountModalConfig: {
                show: true,
                onSuccess: (data) => {
                    this.setState({
                        multiaccounts: _addMultiaccount.bind(this)(data)
                    });
                }
            },
        });
    }

    removeAccount = (e, index) => {
        e.stopPropagation();

        this.setState({
            multiaccounts: _removeMultiaccount.bind(this)(index)
        });
    }

    isCurrentUser = (item) => {
        let currentUser = IdentitySerivce.getUser();
        return currentUser.Id == item.Id;
    }

    changeAccount(data, index) {
        let currentUser = IdentitySerivce.getAuthData();
        currentUser.isCurrentUser = true;

        if (!_isAccountAdded.bind(this)(currentUser.email))
            _addMultiaccount.bind(this)(currentUser);

        _removeMultiaccount.bind(this)(index);

        IdentitySerivce.setAuthData(toJS(data));
        window.location.reload();
    }

    logout = () => {
        IdentitySerivce.logout();
        IdentitySerivce.removeCookies();

        let _rememberSessionData = this.getRememberLoginData();
        _rememberSessionData.password = '';
        _rememberSessionData.remember = false;
        this.setRememberLoginData(_rememberSessionData);

        window.location.href = '/login';
    }

    /** 
     * Get remember login data
     */
    getRememberLoginData() {
        try {
            const _data = localStorage.getItem(REMEMBER_LOGIN_STORAGE_KEY);
            if(_data) {
                return JSON.parse(atob(_data));
            }
        } catch (e) {

        }

        return {};
    }

    /** 
     * Set remember login data
     */
    setRememberLoginData(data) {
        localStorage.setItem(REMEMBER_LOGIN_STORAGE_KEY, btoa(JSON.stringify(data)));
    }

    render() {
        return (
            <React.Fragment>
                <ButtonDropdown id="userDropdown" nav inNavbar isOpen={this.state.isOpen} toggle={() => this.setState({isOpen: !this.state.isOpen})}>
                    <DropdownToggle nav className="py-0">
                        <UserAvatar imgSrc={this.state.user.ProfilePath} username={this.state.user.email} index={0} />
                        <span className="d-none d-md-inline-block align-middle text-white">
                            {this.state.user.email}
                        </span>
                    </DropdownToggle>
                    <DropdownMenu end>
                        <DropdownItem tag="div" toggle={false}>
                            <div className="p-3">
                                <div className="user-overflow">
                                    <div className="d-inline-block">
                                        <UserAvatar username={this.state.user.email} index={0}
                                            height="40px" width="40px" />
                                    </div>
                                    <div className="d-inline-block align-middle">
                                        <div className="lh-1 text-bold">
                                            <span
                                                alt={this.state.user.email}>
                                                {this.state.user.email}
                                            </span>
                                        </div>
                                        <Link to="/reset">
                                            <small>{i18next.t('CHANGE_PASSWORD')}</small>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            {
                                this.state.multiaccounts.length ?
                                    <React.Fragment>
                                        <ul className="multiaccount border-top">
                                            {this.state.multiaccounts.map((data, index) => {

                                                let item = UtilsService.parseJwt(data.token);

                                                return (
                                                    <li
                                                        key={index}
                                                        className={this.isCurrentUser(item) ? 'd-none' : null}
                                                        onClick={() => this.changeAccount(data, index)}
                                                    >
                                                        <UserAvatar username={item.email} index={index + 1} />
                                                        {item.email}
                                                        <Button color="link" className="float-right px-1"
                                                            onClick={(e) => this.removeAccount(e, index)}>
                                                            <i className="fa fa-times font-size-20" aria-hidden="true"></i>
                                                        </Button>
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </React.Fragment> :
                                    null
                            }

                            <Row className="dropdown-item-footer mx-0">
                                <Col xs="6">
                                    {/* <Button size="sm" color="link" onClick={this.addAccount}>
                                        {i18next.t('ADD_ACCOUNT')}
                                    </Button> */}
                                </Col>
                                <Col xs="6" className="text-right">
                                    <Button size="sm" color="link" onClick={this.logout}>
                                        {i18next.t('LOGOUT')}
                                    </Button>
                                </Col>
                            </Row>
                        </DropdownItem>
                    </DropdownMenu>
                </ButtonDropdown >
                {this.state.accountModalConfig.show && <AddAccountModal config={this.state.accountModalConfig} />}
            </React.Fragment>

        )
    }
}

function _getMultiaccounts() {
    return JSON.parse(localStorage.getItem(STORAGE_KEY) || '[]');
}

function _addMultiaccount(data) {
    let accounts = _getMultiaccounts();
    accounts.push(data);
    localStorage.setItem(STORAGE_KEY, JSON.stringify(accounts));

    return accounts;
}

function _removeMultiaccount(index) {
    let accounts = _getMultiaccounts();
    accounts.splice(index, 1);
    localStorage.setItem(STORAGE_KEY, JSON.stringify(accounts));

    return accounts;
}


function _isAccountAdded(email) {
    let accounts = _getMultiaccounts();
    accounts.push(IdentitySerivce.getAuthData()); //Add current user

    for (let ma of accounts) {
        let item = UtilsService.parseJwt(ma.token);
        if (item.email == email)
            return true;
    }
}
