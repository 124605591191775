import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import i18next from 'i18next';
import IdentitySerivce from '../../services/indentity.service';
import Switch from "react-switch";

const STORAGE_KEY = 'acsa-terms-and-conditions-warning-remember';

export default class TermsAndConditionsWarningModal extends React.Component {

    constructor(props) {
        super(props);
        
        this.state = {
            user: IdentitySerivce.getUser() || {},

            switchInput: false
        };
    }
    
    close = () => {
        this.props.config.show = false;
        this.forceUpdate();
    }

    toRememberAgain = () => {

        let conditions_warning_remember = localStorage.getItem(STORAGE_KEY);
            try{
                conditions_warning_remember = JSON.parse(conditions_warning_remember);
                for( let i = 0; i< conditions_warning_remember.length; i++){
                    if(conditions_warning_remember[i] == this.state.user.Email ){
                        conditions_warning_remember.splice(i,1);
                        break;
                    }
                }
            }
            catch(e){
                // reset STORAGE_KEY if not in json format
                conditions_warning_remember = [ this.state.user.Email ];
            }
            // console.log(conditions_warning_remember);
            localStorage.setItem(STORAGE_KEY, JSON.stringify(conditions_warning_remember));
        
        this.props.config.onDoNotRememberAgain && this.props.config.onDoNotRememberAgain();
        this.close();
    }

    doNotRememberAgain = () => {
        let conditions_warning_remember = localStorage.getItem(STORAGE_KEY);
        if(
            conditions_warning_remember &&
            conditions_warning_remember.search(this.state.user.Email) == -1
        ) {
            try{
                conditions_warning_remember = JSON.parse(conditions_warning_remember);
                conditions_warning_remember.push(this.state.user.Email);
                // console.log("User adicionated to STORAGE_KEY");
            }
            catch(e){
                // reset STORAGE_KEY if not in JSON format
                conditions_warning_remember = [ this.state.user.Email ];
            }
            localStorage.setItem(STORAGE_KEY, JSON.stringify(conditions_warning_remember));
        }

        this.props.config.onToRememberAgain && this.props.config.onToRememberAgain();
        this.close();
    }

    switchOnChange = () => {
        this.setState({switchInput: !this.state.switchInput});
    }

    rememberWarningCheck = () => {
        if( this.state.switchInput){
            // console.log("Calling doNotRememberAgain()");
            this.doNotRememberAgain();
        }
        else{
            // console.log("Calling toRememberAgain()");
            this.toRememberAgain();
        }
    }

    render() {
        return (
            <Modal isOpen={this.props.config.show} backdrop={true}>
                <ModalHeader>{i18next.t('WARNING_AND_USE_POLICY')}</ModalHeader>
                <ModalBody>
                    <div>
                        <iframe src={i18next.t('WARNING_AND_USE_POLICY_FRAME_URL')} style={{
                            border: '0 none',
                            width: '100%',
                            height: '550px'
                        }}></iframe>

                        <div className="text-right d-flex align-items-center justify-content-start ml-2">
                        <Switch onChange={this.switchOnChange}
                                checked={this.state.switchInput}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                onColor="#17a2b8"
                            />
                            <span className="text-muted ml-2">{i18next.t('DO_NOT_REMEMBER_AGAIN')}</span>
                        </div>

                        <div className="text-right d-flex align-items-center justify-content-end mr-2">
                            <button type="button" 
                                className="btn btn-primary btn-sm mr-2" 
                                onClick={this.rememberWarningCheck}
                                style={{
                                    'backgroundColor': '#2977a7',
                                    'borderColor': '#2977a7',
                                }}>
                                {i18next.t('TO_REMEMBER_AGAIN')}
                            </button>
                        </div> 
                    </div>
                </ModalBody>
            </Modal>
        );
    }
}

