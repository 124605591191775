import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import i18next from 'i18next';

export default class PasswordInfoModal extends React.Component {

    vm = {
        
    }

    constructor(props) {
        super(props);
    }

    cancel = () => {
        this.props.config.show = false;
    }

    render() {
        return (
            <Modal isOpen={this.props.config.show} backdrop={true}>
                <ModalHeader>{i18next.t('HOW_TO_CHOOSE_PASSWORD_TITLE')}</ModalHeader>
                <ModalBody>
                    <div>
                        <p>
                            {i18next.t('HOW_TO_CHOOSE_PASSWORD_DESCRIPTION_1')}
                            <br />
                            {i18next.t('HOW_TO_CHOOSE_PASSWORD_DESCRIPTION_2')}
                            <br />
                            {i18next.t('HOW_TO_CHOOSE_PASSWORD_DESCRIPTION_3')}
                            <br />
                            {i18next.t('HOW_TO_CHOOSE_PASSWORD_DESCRIPTION_4')}
                            <br />
                            {i18next.t('HOW_TO_CHOOSE_PASSWORD_DESCRIPTION_5')}
                            <br />
                            {i18next.t('HOW_TO_CHOOSE_PASSWORD_DESCRIPTION_6')}
                        </p>
                        <div className="text-right">
                            <button type="button" className="btn btn-default btn-sm mr-2" onClick={this.cancel}>
                                {i18next.t('CLOSE')}
                            </button>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        );
    }
}