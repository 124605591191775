import React from 'react';
import { Link } from 'react-router-dom';
import { Collapse, Navbar, Nav, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import i18next from 'i18next';
import UserSerivce from './../../services/user.service';
import UtilsService from './../../services/utils/utils.service';
import TermsAndConditionsDropdown from './../terms-and-conditions/termsAndConditionsDropdown';
import { DecimalCustomizationDropdown } from './../decimal-customization/decimalCustomizationDropdown';
import UserDropdown from './userDropdown';
import IdentitySerivce from './../../services/indentity.service';

export default class Header extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isSidebarOpen: true,
            isCollapseMenuOpen: false,
            selectedLang: i18next.language
        }
    }

    toggleSidebar = () => {
        if (!this.state.isSidebarOpen) {
            this.showSidebar();
        } else {
            this.hideSidebar();
        }
    }

    showSidebar = () => {
        this.setState({
            isSidebarOpen: true
        });

        document.getElementById("sidebar").classList.remove(['small']);
        document.getElementById("sidebar").style.width = "235px";
        document.getElementById("main").style.marginLeft = "235px";
    }

    hideSidebar = () => {
        this.setState({
            isSidebarOpen: false
        });

        document.getElementById("sidebar").classList.add(['small']);
        document.getElementById("sidebar").style.width = "70px";
        document.getElementById("main").style.marginLeft = "70px";
    }

    changeLang = (lang) => {
        if (this.state.selectedLang == lang)
            return;

        UserSerivce
            .changeLanguage(lang)
            .then(() => {
                this.setState({
                    selectedLang: lang
                });

                let authData = IdentitySerivce.getAuthData();
                authData.language = lang;
                IdentitySerivce.setAuthData(authData);
                
                window.location.reload();
            });
    }

    render() {
        // Check size
        const isMobile = UtilsService.isMobile();
        if(isMobile) {
            setTimeout(() => {
                // Is tablet or mobile
                this.hideSidebar();
            }, 100);
        }

        return (
            <Navbar id="header" dark expand="xs">
                <Link to="/portfolio" id="navbar-logo">
                    <img src="/assets/img/logo-header.png" className="img-responsive d-none d-lg-inline-block" />
                    <img src="/assets/img/logo-header-xs.png" className="img-responsive d-inline-block d-lg-none" />
                </Link>
                <span className="d-inline-block align-middle ml-4 cursor-pointer" onClick={this.toggleSidebar}>
                    <i className="fa fa-bars font-size-25 text-white"></i>
                </span>
                <Collapse isOpen={this.state.isCollapseMenuOpen} navbar>
                    <Nav className="ml-auto align-items-center" navbar id="navbar-items-right">
                        <TermsAndConditionsDropdown />

                        <DecimalCustomizationDropdown />

                        {/*
                            !IdentitySerivce.isAdmin() ? 
                                <div>
                                    <FeedbackDropdown /> 
                                </div>:
                                null
                        */}

                        <UncontrolledDropdown nav inNavbar className="pl-2 pr-3">
                            <DropdownToggle nav tag="span" className="cursor-pointer">
                                <i className="fa fa-globe font-size-22 mr-2 align-middle"></i>
                                <span className="text-uppercase align-middle">{this.state.selectedLang}</span>
                            </DropdownToggle>
                            <DropdownMenu end>
                                <DropdownItem onClick={() => this.changeLang('es')}
                                    className={this.state.selectedLang == 'es' ? 'text-primary' : ''}
                                >
                                    Español (ES)
                                </DropdownItem>
                                <DropdownItem divider />
                                <DropdownItem onClick={() => this.changeLang('en')}
                                    className={this.state.selectedLang == 'en' ? 'text-primary' : ''}
                                >
                                    English (EN)
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>

                        <UserDropdown />

                    </Nav>
                </Collapse>
            </Navbar>
        );
    }
}