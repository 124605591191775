import React from 'react';
import i18next from 'i18next';
import { Card, CardBody, Button, InputGroup, Row, Col } from 'reactstrap';
import Spinner from '../shared/spinner';
import NoData from '../shared/noData';
import FeedbackSerivce from './../../services/feedback.service';
import MessageSerivce from './../../services/utils/message.service';
import UtilsService from '../../services/utils/utils.service';
import ConfirmaModal from './../shared/confirm.modal';

const IsUseful = ({ bool }) => {
    return bool != null ?
        <i className={`fa fa-thumbs-${bool ? 'up text-success' : 'down text-danger'}`}></i> :
        '-';
}

export default class Feedback extends React.Component {

    isLoading;
    items = [];
    searchInput = '';
    confirmModalConfig = {
        show: false
    }

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        _getItems.bind(this)();
    }

    getItemsList = () => {
        return this.searchInput.length ?
            this.items.filter(u =>
                UtilsService.containsSafe(u.fromUser, this.searchInput) ||
                UtilsService.containsSafe(u.message, this.searchInput)
            ) :
            this.items;
    }

    delete = (id) => {
        this.confirmModalConfig.msg = `${i18next.t('ARE_YOU_SURE_TO_DELETE')}`;
        this.confirmModalConfig.show = true;
        this.confirmModalConfig.onSuccess = () => {
            FeedbackSerivce
                .delete(id)
                .then(() => {
                    _getItems.bind(this)();
                    MessageSerivce.success(i18next.t('DELETED'));
                });
        };
    }

    render() {
        return (
            <Card className="historical">
                <CardBody>
                    <Row className="mb-3">
                        <Col md={{ size: 6, offset: 6 }}
                            lg={{ size: 5, offset: 7 }}>
                            <InputGroup>
                                <input type="text"
                                    placeholder={i18next.t('SEARCH_BY_USER_OR_MESSAGE')}
                                    className="form-control w-auto d-inline-block"
                                    value={this.searchInput}
                                    onChange={(e) => this.searchInput = e.target.value} />

                                <div className="input-group-append">
                                    <span className="input-group-text"> 
                                        <i className="fa fa-search"></i>
                                    </span>
                                </div>
                            </InputGroup>
                        </Col>
                    </Row>
                    <table className="table table-sm mb-0">
                        <thead>
                            <tr className="text-muted">
                                <th>Id</th>
                                <th>{i18next.t('USER')}</th>
                                <th>{i18next.t('MESSAGE')}</th>
                                <th className="text-center">{i18next.t('WAS_USEFUL')}</th>
                                <th>{i18next.t('FILE')}</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.getItemsList().map((item, i) =>
                                <tr key={i}>
                                    <td>{item.id}</td>
                                    <td className="text-bold">{item.fromUser}</td>
                                    <td>{item.message}</td>
                                    <td className="text-center">
                                        <IsUseful bool={item.isUseful} />
                                    </td>
                                    <td>
                                        {
                                            item.filePath ?
                                                <a href={item.filePath} target="_blank">
                                                    <i className="fa fa-file-o mr-2"></i>
                                                    {item.filePath}
                                                </a> :
                                                i18next.t('NO_FILE')
                                        }
                                    </td>
                                    <td className="text-center">
                                        <Button color="danger" size="sm" onClick={() => this.delete(item.id)}>
                                            {i18next.t('DELETE')}
                                        </Button>
                                    </td>
                                </tr>
                            )}
                            {!this.getItemsList().length ?
                                <tr>
                                    <td colSpan="99">
                                        <NoData msg={i18next.t(!this.searchInput ? 'NO_DATA' : 'NO_RESULTS')} />
                                    </td>
                                </tr> :
                                null
                            }
                        </tbody>
                    </table>
                    {
                        !this.isLoading && !this.items.length ?
                            <NoData className="pt-3" msg={i18next.t('NO_DATA')} /> :
                            null
                    }
                </CardBody>

                {this.isLoading && <Spinner />}
                {this.confirmModalConfig.show && <ConfirmaModal config={this.confirmModalConfig} />}
            </Card>
        );
    }
}

function _getItems() {
    this.isLoading = true;

    FeedbackSerivce
        .get()
        .then((items) => this.items = items)
        .finally(() => this.isLoading = false);
}