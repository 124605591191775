import { observable, action, computed, toJS } from "mobx";

class Store {

    openedIndexes = [];
    filter = this._getNewFilters();

    //Get
    get isSomeFilter() {
        let filters = toJS(this.filter);
        for (let key in filters) {
            if (key != 'date' && !!filters[key])
                return true;
        }

        return false;
    }

    //Actions
    clearFilters = () => {
        this.filter = this._getNewFilters();
    };

    setFilter = (filter) => this.filter = filter;
    setFilterProp = (prop, value) => this.filter[prop] = value;
    setProp = (prop, value) => this[prop] = value;

    //Private
    _getNewFilters() {
        let d = new Date();
        let to = new Date(d.getFullYear(), d.getMonth(), d.getDate(), 0, 0, 0, 0);
        let from = new Date(to);
        from.setDate(d.getDate() - 30);

        return {
            from,
            to,
            agent: '',
            account: '',
            instrument: '',
            identifier: '',
            period: 'last-month'
        };
    }
}

const HistoricalStore = new Store();

export default HistoricalStore;