import React from 'react';
import { Button } from 'reactstrap';
import i18next from 'i18next';

export default class AuthChangeLangBtn extends React.Component {

    constructor(props) {
        super(props);
    }

    changeLanguage = () => {
        let lang = i18next.language == 'es' ? 'en' : 'es';
        i18next
            .changeLanguage(lang, () => this.props.onChange());
    }

    render() {
        return (
            <Button
                color="link"
                onClick={this.changeLanguage}
            >
                <i className="fa fa-globe mr-2"></i>

                <small>{i18next.language == 'es' ? 'English version' : 'Version Español'}</small>
            </Button>
        )
    }
}