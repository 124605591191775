import React from 'react';
import i18next from 'i18next';
import { Card, CardBody, Button, CardHeader, Row, Col } from 'reactstrap';
import Spinner from '../shared/spinner';
import NoData from '../shared/noData';
import NotificationsService from './../../services/notifications.service';
import { Link } from 'react-router-dom';
import ConfirmaModal from './../shared/confirm.modal';
import MessageSerivce from './../../services/utils/message.service';

export default class Notifications extends React.Component {

    isLoading;
    items = [];
    onlyLastVersions = true;
    confirmModalConfig = {
        show: false
    }

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        _getItems.bind(this)();
    }

    onlyLastVersionsChange = (e) => {
        this.onlyLastVersions = e.target.checked || null;
        _getItems.bind(this)();
    }

    delete = (id) => {
        this.confirmModalConfig.msg = `${i18next.t('ARE_YOU_SURE_TO_DELETE_NOTIFICATIONS')}`;
        this.confirmModalConfig.show = true;

        this.confirmModalConfig.onSuccess = () => {
            NotificationsService
                .delete(id)
                .then(() => {
                    MessageSerivce.success(i18next.t('DELETED'));
                    _getItems.bind(this)();
                });
        };
    }

    render() {
        return (
            <div className="notifications">
                <Card>
                    <CardHeader className="bg-white">
                        <Row className="align-items-center">
                            <Col xs="6">
                                <label className="checkbox">
                                    <input type="checkbox"
                                        checked={!!this.onlyLastVersions}
                                        onChange={this.onlyLastVersionsChange} />
                                    <span className="checkmark"></span>
                                    {i18next.t('SHOW_ONLY_LAST_VERSIONS')}
                                </label>
                            </Col>
                            <Col xs="6" className="text-right">
                                <Link to="/notifications/create"
                                    className="btn btn-sm btn-primary">
                                    <i className="fa fa-plus mr-1"></i>
                                    {i18next.t('CREATE_NOTIFICATION')}
                                </Link>
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody className="py-2">
                        <table className="table table-sm mb-0">
                            <thead>
                                <tr className="text-muted">
                                    <th>{i18next.t('NAME')}</th>
                                    <th>{i18next.t('VERSION')}</th>
                                    <th>{i18next.t('NUMBER_SENT')}</th>
                                    <th>{i18next.t('NUMBER_ACCEPTED_PEOPLE')}</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.items.map((item, i) =>
                                    <tr key={i}>
                                        <td>{item.name}</td>
                                        <td>{item.lastVersion}</td>
                                        <td>{item.deliveredCount}</td>
                                        <td>{item.acceptedCount}</td>
                                        <td className="text-center">
                                            <Link to={`/notifications/edit/${item.id}`} className="mr-3">
                                                <i className="fa fa-edit mr-1"></i>
                                                {i18next.t('EDIT')}
                                            </Link>
                                            <Button color="link" size="sm"
                                                className="p-0 lh-1 text-danger"
                                                onClick={() => this.delete(item.id)}>
                                                <i className="fa fa-trash mr-1"></i>
                                                {i18next.t('DELETE')}
                                            </Button>
                                        </td>
                                    </tr>
                                )}

                                {!this.isLoading && !this.items.length ?
                                    <tr>
                                        <td colSpan="99">
                                            <NoData msg={`${i18next.t('NO_DATA')}`} /> :
                                        </td>
                                    </tr> :
                                    null
                                }
                            </tbody>
                        </table>
                    </CardBody>
                    {this.isLoading && <Spinner />}
                </Card>
                {this.confirmModalConfig.show && <ConfirmaModal config={this.confirmModalConfig} />}
            </div>
        );
    }
}

function _getItems() {
    this.isLoading = true;

    NotificationsService
        .getAdmin(this.onlyLastVersions)
        .then((items) => this.items = items)
        .finally(() => this.isLoading = false)
}