import React from 'react';
import i18next from 'i18next';
import { Row, Col, Card, CardBody, CardHeader, Button, Collapse } from 'reactstrap';
import NoData from '../shared/noData';
import Currency from './../shared/currency';
import { BalanceTotal } from './holdings';
import { Tooltip } from 'react-tooltip'
import UtilsService from './../../services/utils/utils.service';

export default class HoldingInstrumentTable extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            openedIndex: null,
            openedRowAgentIndex: []
        };
    }

    componentDidMount() { }

    toggleCollapse = (index1, index2) => {
        let index = `${index1}${index2}`;

        this.setState({
            openedIndex: (this.state.openedIndex != index) ? index : null
        });
    }

    isCollapseOpen(index1, index2) {
        let index = `${index1}${index2}`;
        return this.state.openedIndex == index;
    }

    onRowClickOpenAgent(index1, subIndex, i) {
        let index = `${index1}${subIndex}${i}`;

        let openedRowAgentIndex = this.state.openedRowAgentIndex;
        let k = openedRowAgentIndex.indexOf(index);
        if (k < 0) {
            openedRowAgentIndex.push(index);
        } else {
            openedRowAgentIndex.splice(k, 1);
        }

        this.setState({
            openedRowAgentIndex: openedRowAgentIndex
        })
    }

    isRowAgentOpened(index1, subIndex, i) {
        let index = `${index1}${subIndex}${i}`;
        return this.state.openedRowAgentIndex.indexOf(index) >= 0;
    }

    isVisible = (agents) => {
        return (agents && agents.length == 1) ?
            { color: '#e8f3f8' } :
            null;
    }

    render() {
        if (!this.props.totals.length)
            return null;

        // Assets
        var assetsByPurpose = [];
        if(this.props.isAsset) {
            this.props.items.map((item, i) => {
                var index = assetsByPurpose.findIndex(x => x.purpose === item.purpose);
                if (index < 0) {
                    assetsByPurpose.push({ 
                        purpose: item.purpose, 
                        items: [
                            item
                        ] 
                    });
                } else {
                    assetsByPurpose[index].items.push(item);
                }
            });
        }

        // Futures
        var futuresByType = [];
        if(!this.props.isAsset) {
            this.props.items.map((item, i) => {
                var index = futuresByType.findIndex(x => x.type === item.type);
                if (index < 0) {
                    futuresByType.push({ 
                        type: item.type, 
                        items: [
                            item
                        ] 
                    });
                } else {
                    futuresByType[index].items.push(item);
                }
            });
        }

        return (
            <Card className="mb-2">
                <CardHeader
                    className="cursor-pointer"
                    onClick={() => this.toggleCollapse(this.props.index, this.props.subIndex)}
                >
                    <div className="align-items-center">
                        <div className="d-block d-sm-flex justify-content-between">
                            <div>
                                <span>
                                    <img src={`/assets/img/${this.props.icon}`} className="mr-2" height="18" width="18" />
                                    {i18next.t(this.props.titleKey)}:
                                </span>
                            </div>
                            <div className="d-flex text-right">
                                <BalanceTotal
                                    totals={this.props.totals}
                                    tag={'strong'} />

                                <Button className="m-0 ml-2 p-0" color="link" size="sm">
                                    <i className={`fa fa-chevron-${this.isCollapseOpen(this.props.index, this.props.subIndex) ? 'up' : 'down'}`}></i>
                                </Button>
                            </div>
                        </div>
                    </div>
                </CardHeader>
                <Collapse isOpen={this.isCollapseOpen(this.props.index, this.props.subIndex)}>
                    <CardBody className="p-0">
                        <div className="table-responsive" style={{ marginBottom: '-1rem' }}>
                            <table className="table table-sm border table-instruments">
                                <thead>
                                    <tr className="font-italic">
                                        <th>{i18next.t('INSTRUMENT')}</th>
                                        {this.props.isAsset ?
                                            <React.Fragment>
                                                {/* Assets */}
                                                <th className="text-right">
                                                    <span>
                                                        {i18next.t('QUANTITY')}
                                                    </span>
                                                </th>
                                                <th className="text-right">
                                                    <span>
                                                        {i18next.t('PRICE')}
                                                    </span>
                                                </th>
                                                <th className="text-right">
                                                    <span>
                                                        {i18next.t('VALUED_ACTIVE')}
                                                    </span>
                                                </th>
                                                <th className="text-right">
                                                    <span>
                                                        {i18next.t('CAPACITY')}
                                                    </span>
                                                </th>
                                                <th className="text-right">
                                                    <span>
                                                        {i18next.t('WARRANTY_VALUE')}
                                                    </span>
                                                </th>
                                            </React.Fragment> :
                                            <React.Fragment>
                                                {/* Futures */}
                                                <th className="text-right">
                                                    <span
                                                        data-tooltip-id="difference-quantity-tooltip"
                                                        data-tooltip-content={i18next.t('DIFFERENCE_QUANTITY_TOOLTIP')}
                                                    >
                                                        {i18next.t('QUANTITY')}
                                                    </span>
                                                </th>
                                                <th className="text-right">
                                                    <span>
                                                        {i18next.t('UNIT')}
                                                    </span>
                                                </th>
                                                <th className="text-right">
                                                    <span 
                                                        data-tooltip-id="difference-price-tooltip"
                                                        data-tooltip-content={i18next.t('DIFFERENCE_PRICE_TOOLTIP')}
                                                    >
                                                        {i18next.t('PRICE')}
                                                    </span>
                                                </th>
                                                <th className="text-right">
                                                    <span>
                                                        {i18next.t('SETTLEMENT')}
                                                    </span>
                                                </th>
                                                <th className="text-right">{i18next.t('DIFFERENCE')}</th>
                                            </React.Fragment>
                                        }
                                        <th className="text-right">{i18next.t('CURRENCY_SYMBOL')}</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {/* Assets */}
                                    {this.props.isAsset ?
                                        assetsByPurpose.map((_, i) =>
                                            <React.Fragment key={i}>
                                                 <tr>
                                                    <td colSpan="99" className="text-bold">
                                                        {_.purpose}
                                                    </td>
                                                </tr>

                                                {_.items.map((item, k) =>
                                                    <React.Fragment key={`${i}-${k}`}>
                                                        <tr 
                                                            onClick={() => this.onRowClickOpenAgent(this.props.index, this.props.subIndex, `${i}-${k}`)}
                                                            className={`${this.isRowAgentOpened(this.props.index, this.props.subIndex, `${i}-${k}`) ? 'active' : null} row-hover`}
                                                        >
                                                            <td>{item.contract || '-'}</td>
                                                            <td className="text-right">
                                                                <Currency amount={item.quantity} symbol={false} />
                                                            </td>
                                                            <td className="text-right">
                                                                <Currency amount={((item.amount / item.capacity) * 100) / item.quantity} symbol={false} />
                                                            </td>
                                                            <td className="text-right">
                                                                <Currency amount={(item.amount / item.capacity) * 100} symbol={false} />
                                                            </td>
                                                            <td className="text-right">
                                                                {item.purpose !== 'Tenencia' ? <React.Fragment>
                                                                    {item.capacity != null ? (item.capacity + '%') : '-'}
                                                                </React.Fragment> : '-' }
                                                            </td>
                                                            <td className="text-right">
                                                                {item.purpose !== 'Tenencia' ? <React.Fragment>
                                                                    <Currency amount={item.amount} symbol={false} />
                                                                </React.Fragment> : '-' }
                                                            </td>
                                                            <td className="text-right">
                                                                {UtilsService.translateCurrencySymbol(item.currencySymbol)}
                                                            </td>
                                                        </tr>
                                                        
                                                        {/* Agents items */}
                                                        {this.isRowAgentOpened(this.props.index, this.props.subIndex, `${i}-${k}`) ?
                                                            item.agents.map((agent, j) =>
                                                                <tr className="row-details font-size-11" key={j}>
                                                                    <td>
                                                                        <div>
                                                                            <strong>{i18next.t('ACCOUNT')}: </strong>
                                                                            <span>
                                                                                <span >{agent.accountCode}</span>
                                                                                <span>{!!agent.accountCode ? ' - ' : ''} {agent.account || '-'}</span>
                                                                            </span>
                                                                        </div> 
                                                                        <div>
                                                                            <strong>{i18next.t('AGENT')}: </strong>
                                                                            <span>{agent.agent || '-'}</span>
                                                                        </div>
                                                                        {agent.counterpartAccount ?
                                                                            <div>
                                                                                <strong>{i18next.t('COUNTERPARTACCOUNT')}: </strong>
                                                                                <span >{agent.counterpartAccountCode}</span>
                                                                                <span>{!!agent.counterpartAccountCode ? ' - ' : ''} {agent.counterpartAccount || '-'}</span>
                                                                            </div> : null
                                                                        }
                                                                    </td> 
                                                                    <td className="text-right">
                                                                        <Currency amount={agent.quantity} symbol={false} />
                                                                    </td>
                                                                    <td className="text-right">
                                                                        <Currency amount={((agent.amount / agent.capacity) * 100) / agent.quantity} symbol={false} />
                                                                    </td>
                                                                    <td className="text-right">
                                                                        <Currency amount={(agent.amount / agent.capacity) * 100} symbol={false} />
                                                                    </td>
                                                                    <td className="text-right">
                                                                        {item.purpose !== 'Tenencia' ? <React.Fragment>
                                                                            {agent.capacity != null ? (agent.capacity + '%') : '-'}
                                                                        </React.Fragment> : '-' }
                                                                    </td>
                                                                    <td className="text-right">
                                                                        {item.purpose !== 'Tenencia' ? <React.Fragment>
                                                                            <Currency amount={agent.amount} symbol={false} />
                                                                        </React.Fragment> : '-' }
                                                                    </td>
                                                                    <td className="text-right">
                                                                        {UtilsService.translateCurrencySymbol(item.currencySymbol)}
                                                                    </td>
                                                                </tr>
                                                            ) :
                                                            null
                                                        }
                                                    </React.Fragment>
                                                )}
                                            </React.Fragment>
                                        )
                                        : null
                                    }

                                    {/* Futures */}
                                    {!this.props.isAsset ?
                                        futuresByType.map((_, i) =>
                                            <React.Fragment key={i}>
                                                <tr>
                                                    <td colSpan="99" className="text-bold">
                                                        {_.type}
                                                    </td>
                                                </tr>

                                                {_.items.map((item, k) =>
                                                    <React.Fragment key={`${i}-${k}`}>
                                                        <tr
                                                            onClick={() => this.onRowClickOpenAgent(this.props.index, this.props.subIndex, `${i}-${k}`)}
                                                            className={`${this.isRowAgentOpened(this.props.index, this.props.subIndex, `${i}-${k}`) ? 'active' : null} row-hover`}
                                                        >
                                                            <td>{item.contract || '-'}</td>
                                                            <td className="text-right">
                                                                <Currency amount={item.quantity} symbol={false} />
                                                            </td>
                                                            <td className="text-right">
                                                                { item.unitOfMeasurement ? UtilsService.translateUnitOfMeasurement(item.unitOfMeasurement) : '-' }
                                                            </td>
                                                            <td className="text-right">
                                                                <Currency amount={item.amount} symbol={false} />
                                                            </td>
                                                            <td className="text-right">
                                                                <Currency amount={item.settlement} symbol={false} />
                                                            </td>
                                                            <td className={"text-right " + (item.dailySettlement < 0 ? 'text-danger': '')}>
                                                                <Currency amount={item.dailySettlement} symbol={false} />
                                                            </td>
                                                            <td className="text-right">
                                                                {UtilsService.translateCurrencySymbol(item.currencySymbol)}
                                                            </td>
                                                        </tr>

                                                        {/* Agents items */}
                                                        {this.isRowAgentOpened(this.props.index, this.props.subIndex, `${i}-${k}`) ?
                                                            item.agents.map((agent, j) =>
                                                                <tr className="row-details font-size-11" key={j}>
                                                                    <td>
                                                                        <div>
                                                                            <strong>{i18next.t('ACCOUNT')}: </strong>
                                                                            <span>
                                                                                <span >{agent.accountCode}</span>
                                                                                <span>{!!agent.accountCode ? ' - ' : ''} {agent.account || '-'}</span>
                                                                            </span>
                                                                        </div> 
                                                                        <div>
                                                                            <strong>{i18next.t('AGENT')}: </strong>
                                                                            <span>{agent.agent || '-'}</span>
                                                                        </div>
                                                                        {agent.counterpartAccount ?
                                                                            <div>
                                                                                <strong>{i18next.t('COUNTERPARTACCOUNT')}: </strong>
                                                                                <span >{agent.counterpartAccountCode}</span>
                                                                                <span>{!!agent.counterpartAccountCode ? ' - ' : ''} {agent.counterpartAccount || '-'}</span>
                                                                            </div> : null
                                                                        }
                                                                    </td> 
                                                                    <td className="text-right">
                                                                        <Currency amount={agent.quantity} symbol={false} />
                                                                    </td>
                                                                    <td className="text-right">
                                                                        { item.unitOfMeasurement ? UtilsService.translateUnitOfMeasurement(item.unitOfMeasurement) : '-' }
                                                                    </td>
                                                                    <td className="text-right">
                                                                        <Currency amount={agent.amount} symbol={false} />
                                                                    </td>
                                                                    <td className="text-right">
                                                                        <Currency amount={agent.settlement} symbol={false} />
                                                                    </td>
                                                                    <td className="text-right">
                                                                        <Currency amount={agent.dailySettlement} symbol={false} />
                                                                    </td>
                                                                    <td className="text-right">
                                                                        {UtilsService.translateCurrencySymbol(item.currencySymbol)}
                                                                    </td>
                                                                </tr>
                                                            ) :
                                                            null
                                                        }
                                                    </React.Fragment>
                                                )}
                                            </React.Fragment>
                                        )
                                        : null
                                    }

                                    {/* Balance */}
                                    <tr className="border-dark" style={{ height: '40px', borderTop: '2px solid' }}>
                                        <td colSpan="1" className="text-bold">
                                            {i18next.t(this.props.isAsset ? 'BALANCE' : 'TOTAL_DIFFERENCE')}:
                                            </td>
                                        <td colSpan="99" className="text-right">
                                            <BalanceTotal
                                                totals={this.props.totals} />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        {!this.props.items.length ?
                            <NoData msg={i18next.t('NO_RESULTS')} /> :
                            null
                        }
                    </CardBody>
                </Collapse>
           
                <Tooltip id="price-tooltip" />
                <Tooltip id="valued-active-tooltip" />
                <Tooltip id="difference-price-tooltip" />
                <Tooltip id="difference-quantity-tooltip" />
                <Tooltip id="difference-settlement-tooltip" />
            </Card>
        );
    }
}