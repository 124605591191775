import { observable, action, computed, toJS } from "mobx";

class Store {

    view = 'INSTRUMENT';
    
    filter = {
        date: new Date(),
        agent: '',
        account: '',
        instrument: '',
    };

    //Get
    get isSomeFilter() {
        let filters = toJS(this.filter);
        for (let key in filters) {
            if (key != 'date' && !!filters[key])
                return true;
        }

        return false;
    }

    //Actions
    clearFilters = () => {
        this.filter = {
            date: this.filter.date,
            agent: '',
            account: '',
            instrument: '',
        }
    };
    setFilter = (filter) => this.filter = filter;
    setFilterProp = (prop, value) => this.filter[prop] = value;
    setProp = (prop, value) => this[prop] = value;
}

const HoldingStore = new Store();

export default HoldingStore;