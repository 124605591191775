import React from 'react';
import i18next from 'i18next';

export default class SEUO extends React.Component {
    render() {
        return (
            <div style={{
                position: "fixed",
                background: "#f1f1f1",
                color: "#444",
                bottom: "2px",
                right: "10px",
                left: 0,
                textAlign: "right"
            }}>
                <span title={i18next.t('SEUO_TITLE')}>
                    {i18next.t('SEUO')}
                </span>
            </div>
        );
    }
}